<template>
  <Layout>
    <Toolbar title="Pipeline" :show-charts="showCharts" @on-charts="onCharts" @on-panel="onPanel" />


    <div v-if="!showCharts" class="expansion-panels">
      <StatusPanel v-for="item in status" :key="item.id" :name="item.name" :color="item.color" :icon="item.icon"
        :count="item?.data?.length" :active="item.active" :items="getData(item.name)" :status="dataStatus"
        @click="changeActivePhase(item.id)" @add="add($event)" @open-user="openUser"
        @open-kickoff="openModal('kickoffModal')" @open-drawer="openDrawer($event)"
        @open-probability="openProbability($event)" @open-candidate-list="openCandidateList" />
    </div>
    <Charts v-else />


    <div v-if="isLoading" class="loading">
      <div class="loading__content">
        <div class="loading__spinner"></div>
        <p>Carregando...</p>
      </div>
    </div>
    <UserModal ref="userModal" :user="user" />

    <KickoffModal ref="kickoffModal" />
    <Menu ref="menu" :open-bottom-drawer="openBottomDrawer" :item="drawer"
      @open-activity-modal="openModal('novaAtividadeModal')" @open-company="openCompanyModal($event)"
      @open-valor="openModal('openValor')" @open-valor-correcao="openModal('openValorCorrecao')" @open-user="openUser"
      @open-drawer-to-business="openDrawer(toBusiness)" @open-candidate-list="openCandidateList" />
    <CandidateList ref="candidateList" @open-drawer="openDrawer($event)" @open-user="openUser" />


    <EmpresaModal ref="empresaModal" :company="company" @open-contact="openContact"
      @open-council-modal="openCouncilModal" />
    <ContatosModal ref="contatoModal" @close-contact="closeContact" />
    <ConselhoModal ref="conselhoModal" @close-council="closeCouncil" />
    <ValorNegocioCorrecao ref="openValorCorrecao" />
    <ValorNegocio ref="openValor" />
  </Layout>
</template>

<script>
import Layout from '@/layouts/default.vue'
import Toolbar from '@/components/Toolbar.vue'
import StatusPanel from '@/components/StatusPanel.vue'
import UserModal from '@/components/modals/user.vue'
import KickoffModal from '@/components/modals/kickoff.vue'
import EmpresaModal from '@/components/modals/empresa.vue'
import ContatosModal from '@/components/modals/contatos.vue'
import ConselhoModal from '@/components/modals/conselho.vue'
import mixins from '@/mixins'
import Menu from '@/components/Menu.vue'
import CandidateList from '../components/CandidateList.vue'
import Charts from '../components/Charts.vue'
import ValorNegocio from '../components/modals/valor-negocio.vue'
import ValorNegocioCorrecao from '../components/modals/valor-negocio-correcao.vue'
import { mapActions, mapGetters } from 'vuex'
import cookieConfig from '@/store/cookieConfig.js'

export default {
  name: 'HomePage',
  components: {
    Layout,
    Toolbar,
    StatusPanel,
    UserModal,
    KickoffModal,
    CandidateList,
    Menu,
    EmpresaModal,
    ContatosModal,
    ConselhoModal,
    Charts,
    ValorNegocio,
    ValorNegocioCorrecao
  },
  mixins: [mixins],
  data () {
    return {
      company: {},
      dataStatus: [],
      user: {},
      drawer: {},
      isLoading: false,
      bottomDrawerOpen: false,
      showCharts: false,
      toLead: {
        color: 'jambo',
        icon: 'users',
        name: 'Leads'
      },
      toBusiness: {
        color: 'turquesa',
        icon: 'badge-dollar',
        name: 'Negócios'
      }
    }
  },
  computed: {
    ...mapGetters({
      status: 'home/status',
      filteredProspects: 'home/filteredProspectsData',
      filteredLeads: 'home/filteredLeadsData',
      filteredBusiness: 'home/filteredBusinessData',
      filteredProjects: 'home/filteredProjectsData',
      filteredPlaceds: 'home/filteredPlacedsData',
      currentStep: 'session/currentStep'
    })
  },
  watch: {
    currentStep (value) {
      this.setCurrentStep(value);
      // Using Vue.nextTick to ensure that all DOM updates have been completed before calling getStepData
      this.$nextTick(() => {
        this.getStepData(value)
      })
    }
  },

  beforeMount () {
    const userToken = cookieConfig.getCookie('token_plonge')

    if (!userToken) {
      this.$router.push('/login')
    }
  },

  async mounted () {
    if (this.$route.path !== '/login') {
      this.setCurrentStep(this.currentStep);
      this.changeActivePhase(this.currentStep)
      try {
        this.isLoading = true

        await Promise.all([this.getAllResponsible(), this.getDateCloses()])
        this.getStepData(this.currentStep)
      } catch (error) {
        return this.$toast.error('Erro ao carregar dados. Tente novamente.' + error)
      } finally {
        this.isLoading = false
      }
    }
  },

  methods: {
    ...mapActions({
      changeActivePhase: 'home/changeActivePhase',
      getUserData: 'auth/getUserData',
      getProspects: 'home/getOnlyProspects',
      getLeads: 'home/getOnlyLeads',
      getBusiness: 'home/getOnlyBusiness',
      getProjects: 'home/getOnlyProjects',
      getPlaceds: 'home/getOnlyPlaceds',
      getAllResponsible: 'contacts/getAllResponsible',
      getDateCloses: 'home/getDateCloses',
      setCurrentStep: 'home/setCurrentStep',
      getUserFavorites: 'home/getUserFavorites',
    }),
    getStepData (step) {
      const actions = [
        this.getProspects,
        this.getLeads,
        this.getBusiness,
        this.getProjects,
        this.getPlaceds
      ]

      //this.getFavorites(step);

      if (step >= 1 && step <= 5) {
        !this.status[step - 1]?.data?.length && actions[step - 1]()
      }
    },
    openCompanyModal (event) {
      console.log("Home.openCompanyModal");
      this.company = event
      this.$refs.empresaModal.open(this.company)
    },
    openCandidateList () {
      console.log("Home.openCandidateList");
      this.$refs.candidateList.open()
    },
    openUser (user) {
      console.log("Home.openUser");
      this.user = user
      this.openModal('userModal')
    },
    openDrawer (config) {
      console.log("Home.openDrawer");
      this.getUserFavorites(this.currentStep);

      this.drawer = config || {
        color: 'lavanda',
        icon: 'bullhorn',
        name: 'Prospect'
      }

      this.$refs.menu.open(this.drawer)
    },
    openContact (listType) {
      this.closeModal('empresaModal')
      this.openModal('contatoModal', listType)
    },
    openCouncilModal () {
      this.closeModal('empresaModal')
      this.openModal('conselhoModal')
    },
    openActivityModal () {
      this.openModal('novaAtividadeModal')
    },
    closeActivityModal () {
      this.closeModal('novaAtividadeModal')
    },
    closeContact () {
      this.openModal('empresaModal')
    },
    closeCouncil () {
      this.openModal('empresaModal')
    },
    openBottomDrawer () {
      this.bottomDrawerOpen = true
    },
    closeBottomDrawer () {
      this.bottomDrawerOpen = false
    },
    openProbability (type) {
      this.$refs.probabilidade.open(type)
    },
    onPanel () {
      this.showCharts = false
    },
    onCharts () {
      this.showCharts = true
    },
    getData (stage) {
      if (stage === 'Negócios') return this.filteredBusiness
      if (stage === 'Leads') return this.filteredLeads
      if (stage === 'Projetos') return this.filteredProjects
      if (stage === 'Placeds') return this.filteredPlaceds

      return this.filteredProspects
    }
  }
}
</script>

<style lang="scss">
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    &__spinner {
      border: 8px solid rgba(255, 255, 255, 0.3);
      border-top: 8px solid white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
    }
  }
}
</style>
