<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content">
      <div class="dialog-content-header">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-user"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2">Novo contato</div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-times"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-2 bg-default">
          <div class="poster mb-2">
            <b-preview-file id="logoContact" v-model="file">
              <template #default="{ url }">
                <div class="poster mb-2">
                  <em class="icon fal fa-user"></em>
                  <img v-if="url" :src="url" alt="Foto do contato" />
                  <div class="btn-camera">
                    <em class="fal fa-camera"></em>
                  </div>
                </div>
              </template>
            </b-preview-file>
          </div>
          <b-textfield v-model="name" label="Nome" :hint="errorName"></b-textfield>
          <b-textfield v-model="cpf" label="CPF" type="cpf" :hint="errorCpf"></b-textfield>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <b-textfield v-model="form.linkedin" label="Linkedin" class="w-100"></b-textfield>
            </div>
            <div class="col-xs-12 col-sm-6">
              <b-textfield v-model="form.phones[0].phone" type="phone" label="Telefone" class="w-100"></b-textfield>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <b-textfield v-model="actualJob" label="Cargo Atual"></b-textfield>
            </div>
            <div class="col-xs-12 col-sm-6">
              <b-autocomplete v-model="actualCompanyId" :options="companies" option-text="name" option-value="id"
                label="Empresa Atual" class="w-100" @input="searchCompany" />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <b-autocomplete v-model="form.gender" :options="genders" option-text="name" option-value="type"
                label="Gênero" class="w-100" />
            </div>
            <div class="col-xs-12 col-sm-6">
              <b-textfield v-model="email" label="E-mail" class="w-100" :hint="errorEmail"></b-textfield>
            </div>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer justify-end">
        <button class="btn outlined sm" @click="close()">Cancelar</button>
        <button class="btn btn-coral sm" @click="saveContact()">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '../../helpers'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      name: '',
      email: '',
      cpf: null,
      actualJob: null,
      actualCompanyId: null,
      dialog: false,
      emails: {
        label: '',
        email: ''
      },
      phones: {
        label: '',
        phone: ''
      },
      genders: [
        {
          name: 'Masculino',
          type: 'M'
        },
        {
          name: 'Feminino',
          type: 'F'
        }
      ],
      url: null,
      file: null,
      errorName: '',
      errorEmail: '',
      errorCpf: '',
      form: {
        name: null,
        cpf: null,
        photo: null,
        gender: null,
        linkedin: null,
        observation: null,
        roleId: null,
        actualJob: null,
        actualCompanyId: null,
        emails: [
          {
            label: 'none',
            email: ''
          }
        ],
        phones: [
          {
            label: 'none',
            phone: ''
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/contacts',
      roleOptions: 'contacts/options',
      comercialContacts: 'contacts/contacts',
      selectedContacts: 'company/selectedContacts',
      companies: 'company/companies'
    })
  },
  watch: {
    name: function (name) {
      this.errorName = ''
      if (name === null) return
      this.comercialContacts.forEach((object) => {
        if (object.name === name) {
          this.errorName = 'Ja existe este nome cadastrado.'
        }
      })
    },
    cpf: function (cpf) {
      this.errorCpf = ''
      if (cpf === null) return
      this.comercialContacts.forEach((object) => {
        if (object.cpf === cpf) {
          this.errorCpf = 'Ja existe este CPF cadastrado.'
        }
      })
    },
    email: function (email) {
      this.errorEmail = ''
      if (email === null) return
      this.comercialContacts.forEach((object) => {
        object?.emails?.forEach((objectEmail) => {
          if (objectEmail.email === email) {
            this.errorEmail = 'Ja existe este email cadastrado.'
          }
        })
      })
    }
  },
  methods: {
    ...mapActions({
      getRoles: 'contacts/loadOptions',
      registerContact: 'contacts/registerContacts',
      showSnackbar: 'snackbar/showSnackbar',
      setSelectedContacts: 'company/setSelectedContacts',
      loadContacts: 'contacts/loadContacts',
      loadCompanies: 'company/loadCompanies'
    }),
    async open () {
      this.dialog = true
      await Promise.all([this.getRoles(), this.loadCompanies()])
    },
    close () {
      this.form = {
        name: null,
        cpf: null,
        actualJob: null,
        actualCompanyId: null,
        photo: null,
        gender: null,
        linkedin: null,
        observation: null,
        roleId: null,
        emails: [
          {
            label: 'none',
            email: ''
          }
        ],
        phones: [
          {
            label: 'none',
            phone: ''
          }
        ]
      }
      this.name = null
      this.cpf = null
      this.actualJob = null
      this.actualCompanyId = null
      this.email = null
      this.url = null
      this.file = null
      this.dialog = false
      this.$emit('closeContact')
    },
    addEmail () {
      if (!this.emails.label || !this.emails.email) return
      const newMail = Object.assign({}, this.emails)
      this.form.emails.push(newMail)
    },
    deleteMail (label) {
      this.form.emails = this.form.emails.filter(
        (email) => email.label !== label
      )
    },
    addPhone () {
      if (!this.phones.label || !this.phones.phone) return
      const newPhone = Object.assign({}, this.phones)
      this.form.phones.push(newPhone)
    },
    deletePhone (label) {
      this.form.phones = this.form.phones.filter(
        (phone) => phone.label !== label
      )
    },
    async saveContact () {
      try {
        if (this.file) {
          const formData = new FormData()

          formData.append('file', this.file)
          const result = await this.$axios
            .post(
              'https://bluecore-plonge-pipeline-api-homolog.azurewebsites.net/api/upload/blob',
              formData
            )
            .catch((error) => {
              this.showSnackbar({
                message: error.parsedErrorText || 'Falha no upload da imagem',
                secondsToClose: 5,
                type: 'error'
              })

              return Promise.reject(error)
            })
          this.form.photo = result.data?.pathName
        }
        this.form.name = this.name
        this.form.cpf = this.cpf
        this.form.actualJob = this.actualJob
        this.form.actualCompanyId = this.actualCompanyId
        this.form.emails =
          this.email !== '' ? [{ label: 'none', email: this.email }] : []
      } catch (error) {
        console.error(error)
        return
      }

      const contact = Object.assign({}, this.form)

      contact.phones = contact?.phones?.filter((row) => row.phone.length)

      this.registerContact(contact).then((result) => {
        this.setSelectedContacts([result, ...this.selectedContacts])

        this.showSnackbar({
          message: 'Contato salvo com sucesso',
          secondsToClose: 5,
          type: 'success'
        })

        this.close()
      })
    },
    async searchCompany (value) {
      if (value.length < 2) {
        return
      }
      this.searchCompanyEvent = true
      debounce(this.loadCompanies({ query: value, refresh: true }), 1000)
    }
  }
}
</script>
