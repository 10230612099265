<template>
  <div v-if="item" class="drawer" :class="{ active: drawer }">
    <div class="drawer-content">
      <div class="drawer-content-header" :class="`bg-${item.color || stages[item.stepId]?.color}`">
        <div class="btn icon btn-highlight no-hover">
          <em class="fal" :class="`fa-${item.icon || stages[item.stepId]?.icon}`"></em>
        </div>
        <div class="flex-grow-1 ml-2 mr-2 d-flex align-center">
          <span class="h4">{{ currentCompany?.name ?? 'Empresa' }}</span>
        </div>
        <button class="btn icon favorite" :class="{ active: userFavorites.includes(details.id) }"
          @click="toggleFavorite()">
          <em class="fal fa-heart"></em>
        </button>
        <button class="btn btn-white text icon" @click="close()">
          <em class="fal fa-arrow-right-to-line"></em>
        </button>
      </div>
      <div class="d-flex pa-1 gap-8 bg-contrast">
        <b-dropdown>
          <template #trigger="{ on: menu }">
            <b-tooltip>
              <template #activator="{ on: tooltip }">
                <b-avatar size="xs" :class="{ select: details.responsible }" :name="details?.responsible?.name"
                  v-on="{ ...tooltip, ...menu }">
                  <em v-if="!details?.responsible?.name" class="fal fa-user text-color-light"></em>
                  <img v-if="details.responsible" :src="details.responsible.photo" :alt="details.responsible.name" />
                </b-avatar>
              </template>
              <span>Responsável</span>
            </b-tooltip>
          </template>
          <template #content>
            <div class="list">
              <button v-if="details.responsible" class="list-item">
                <b-avatar :name="details.responsible.name" size="xxs">
                  <img :src="details.responsible.photo" :alt="details.responsible.name" />
                </b-avatar>
                <span class="paragraph xs text-left">{{
                  details.responsible.name
                }}</span>
              </button>
              <button v-for="item in details.contacts" :key="'responsible-contact-' + item.id" class="list-item"
                @click="setResponsible(item)">
                <b-avatar :name="item.name" size="xxs">
                  <img :src="item.photo" :alt="item.name" />
                </b-avatar>
                <span class="paragraph xs text-left">{{ item.name }}</span>
              </button>
            </div>
          </template>
        </b-dropdown>
        <span class="flex-grow-1"></span>
        <div class="avatar-group">
          <b-avatar v-for="item in details.contacts" :key="'avtr' + item.id" class="with-border" size="xs"
            :name="item.name" @click="openUser(item)">
            <img :src="item.photo" :alt="item.name" />
          </b-avatar>
        </div>
        <b-dropdown x="right">
          <template #trigger="{ on }">
            <div class="avatar xs text-color-light" v-on="on">
              <em class="fal fa-plus"></em>
            </div>
          </template>
          <template #content>
            <div class="list">
              <b-checkbox v-for="item in pipelineContactsList" :id="'multiple-contacts-' + item.contactId"
                :key="'multiple-contacts-' + item.contactId" v-model="contactsIds" :input-value="item.contactId"
                class="invert list-item" @click.prevent.stop="changePipelineContacts(item.contactId)">
                <template #label>
                  <b-avatar :name="item.name" class="mr-2" size="xxs">
                    <img :src="item.photo" :alt="item.name" />
                  </b-avatar>
                  <div class="paragraph sm flex-grow-1 overflow-hidden">
                    {{ item.name }}
                  </div>
                </template>
              </b-checkbox>
            </div>
          </template>
        </b-dropdown>
      </div>
      <span class="divider"></span>
      <div class="drawer-content-body">
        <div class="tab">
          <div class="tab-header">
            <button v-for="elem in tabs" :key="elem.name" class="btn sm" :class="{ active: tab === elem.name }"
              @click="changeTab(elem.name)">
              {{ elem.label }}
            </button>
            <span class="spacer"></span>
            <b-tooltip x="right">
              <template #activator="{ on }">
                <div class="btn icon sm" :class="{ 'btn-coral': tab === 'anexos' }" v-on="on"
                  @click="changeTab('anexos')">
                  <em class="fal fa-paperclip"></em>
                </div>
              </template>
              Anexos
            </b-tooltip>
          </div>
          <span class="divider"></span>
          <Transition name="slide-fade" mode="out-in">
            <component :is="tab" :name="item.name || stages[item.stepId]?.name"
              :stage="item.name || stages[item.stepId]?.name" :details="details" :current-stage="currentStage"
              @openActivityModal="$emit('openActivityModal')" @openCompany="openCompanyModal($event)"
              @openValor="$emit('openValor')" @openValorCorrecao="$emit('openValorCorrecao')" @close="close"
              @openUser="openUser" @openDrawerToLead="$emit('openDrawerToLead')"
              @openDrawerToBusiness="$emit('openDrawerToBusiness')" @closeMenu="close"
              @openCandidateList="openCandidateList">
            </component>
          </Transition>
        </div>
      </div>
      <span class="divider"></span>
      <div class="drawer-content-footer justify-end bg-contrast">
        <button class="btn outlined sm" @click="cancel()">Cancelar</button>
        <button :class="isValid ? 'btn btn-indigo sm' : 'btn btn-coral sm'" @click="save">
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Detalhes from './tab/Detalhes.vue'
import Atividades from './tab/Atividades.vue'
import Vaga from './tab/Vaga.vue'
import Financeiro from './tab/Financeiro.vue'
import Anexos from './tab/Anexos.vue'
import mixins from '@/mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MenuComponent',
  components: {
    Detalhes,
    Atividades,
    Vaga,
    Financeiro,
    Anexos
  },
  mixins: [mixins],
  data () {
    return {
      item: {},
      stage: {
        PROSPECT: 1,
        LEAD: 2,
        BUSINESS: 3,
        PROJECTS: 4,
        PLACEDS: 5
      },
      error: false,
      sucess: false,
      drawer: false,
      tab: 'detalhes',
      tabs: [
        {
          name: 'detalhes',
          label: 'Detalhes'
        },
        {
          name: 'atividades',
          label: 'Atividades'
        },
        {
          name: 'vaga',
          label: 'Vaga'
        },
        {
          //testeteste
          name: 'financeiro',
          label: 'Financeiro'
        }
      ],
      contactsIds: [],
      details: {
        new: '',
        id: '',
        responsible: null,
        contacts: [],
        companyId: '',
        chanceToBecomeDeal: 0,
        chanceToCloseDeal: 0,
        expectedDateToCloseDeal: null,
        code: null,
        prospectStatusId: '',
        leadStatusId: '',
        businessStatusId: '',
        projectStatusId: '',
        placedStatusId: '',
        monitoringFraquencyId: '',
        businessRejectStatusId: '',
        originId: '',
        pipelineServiceTypeId: '',
        note: '',
        internalFupPriorityId: '',
        customerFupPriorityId: '',
        candidancy: [],
        candidancy_status: '',
        candidateWinner: null,
        entryDatePlaced: null,
        fileContract: '',
        contract: null,
        leadStep: null,
        leadStepDateCreated: null,
        businessStep: null,
        businessStepDateCreated: null,
        projectStep: null,
        projectStepDateCreated: null,
        placedStep: null,
        placedStepDateCreated: null
      }
    }
  },
  computed: {
    ...mapGetters({
      origins: 'home/origins',
      users: 'users/users',
      currentCompany: 'company/currentCompany',
      contacts: 'contacts/contacts',
      lead: 'leads/lead',
      businessOptions: 'business/options',
      prospectOptions: 'prospect/options',
      userFavorites: 'home/userFavorites',
      currentStep: 'session/currentStep',
      currentFolder: 'attachments/currentFolder'
    }),
    prospectOriginId () {
      const [origin] =
        this.origins?.filter(
          (origin) => origin.name.trim().toUpperCase() === 'PROSPECT'
        ) || []

      if (!origin) return ''

      return origin.id
    },
    currentStage () {
      let step = 0

      switch (this.item.name) {
        case 'Placeds':
          step = this.stage.PLACEDS
          break
        case 'Projetos':
          step = this.stage.PROJECTS
          break
        case 'Negócios':
          step = this.stage.BUSINESS
          break
        case 'Leads':
          step = this.stage.LEAD
          break
        case 'Prospect':
        default:
          step = this.stage.PROSPECT
      }

      return step
    },
    contactList () {
      const contacts = Object.assign([], this.contacts)
      return contacts.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
    },
    pipelineContactsList () {
      return this.users.filter(
        (contact) =>
          contact !== this.details.responsible &&
          contact.statusId === 1 &&
          contact.tenantId === 1
      )
    }
  },
  watch: {
    contactsIds () {
      this.details.contacts = this.users.filter((user) =>
        this.contactsIds.includes(user.contactId)
      )
    },
    'details.responsible' () {
      this.contactsIds = this.contactsIds.filter(
        (id) => id !== this.details.responsible.contactId
      )
    },
    drawer (value) {
      if (!!value && !this.contacts.length) {
        this.loadContacts()
      }
    }
  },
  mounted () {
    this.details.originId = this.prospectOriginId
    this.loadUsers()
  },
  methods: {
    ...mapActions({
      setCurrentPipeline: 'attachments/setCurrentPipeline',
      loadPipelineActivities: 'activities/loadPipelineActivities',
      loadUsers: 'users/loadUsers',
      savePosition: 'home/savePosition',
      loadLead: 'leads/getLeadById',
      setCurrentCompany: 'company/setCurrentCompany',
      getProspects: 'home/getOnlyProspects',
      getLeads: 'home/getOnlyLeads',
      getBusiness: 'home/getOnlyBusiness',
      getProjects: 'home/getOnlyProjects',
      getPlaceds: 'home/getOnlyPlaceds',
      changeActivePhase: 'home/changeActivePhase',
      showSnackbar: 'snackbar/showSnackbar',
      addFavorites: 'home/addFavorites',
      removeFavorite: 'home/removeFavorite',
      getFavorites: 'home/getFavorites',
      setNull: 'home/setNull',
      openIfChanged: 'dialog/openIfChanged',
      setSnapshot: 'dialog/setSnapshot',
      updateJob: 'jobs/updateJob',
      getCompanyById: 'company/getCompanyById',
      getDirRoot: 'attachments/getDirRoot',
      saveFileContract: 'attachments/saveFileContract',
      createFinancial: 'jobs/createFinancial',
      loadContacts: 'contacts/loadContacts',
      resetData: 'jobs/resetData'
    }),
    openCandidateList () {
      this.$emit('openCandidateList')
    },
    openCompanyModal (event) {
      this.$emit('openCompany', event)
    },
    changeTab (tab) {
      this.tab = tab
    },
    setResponsible (item) {
      this.details.responsible = item
    },
    open (config) {
      console.log("Menu.function.open", config);
      //this.getFavorites(this.currentStep);
      //console.log("Menu.open.this.userFavorites:", this.userFavorites);
      this.clearForm()

      this.item = config

      this.changeTab(this.item.tab || 'detalhes')

      if (!this.item.pipelineId) {
        this.details.new = true
        this.drawer = true
        return
      }

      this.details.new = false
      this.setCurrentPipeline(this.item.pipelineId)
      const data = { leadId: this.item.pipelineId, stepId: this.item.stepId }
      this.loadLead(data).then(() => {
        this.details.companyId = this.lead.companyId || ''
        this.details.code = this.lead.code
        this.details.contacts = this.lead.contacts || []
        this.details.prospectStatusId = this.lead.prospectStatusId || ''
        this.details.leadStatusId = this.lead.leadStatusId || ''
        this.details.businessStatusId = this.lead.businessStatusId || ''
        this.details.projectStatusId = this.lead.projectStatusId || ''
        this.details.placedStatusId = this.lead.placedStatusId || ''
        this.details.monitoringFraquencyId =
          this.lead.monitoringFraquencyId || ''
        this.details.internalFupPriorityId =
          this.lead.internalFupPriorityId || ''
        this.details.customerFupPriorityId =
          this.lead.customerFupPriorityId || ''
        this.details.originId = this.lead.originId || ''
        this.details.responsible = this.lead.responsible
        this.details.pipelineServiceTypeId =
          this.lead.pipelineServiceTypeId || ''
        this.details.name = this.lead.name
        this.details.note = this.lead.note
        this.details.businessRejectStatusId =
          this.lead.businessRejectStatusId || ''
        this.details.expectedDateToCloseDeal =
          this.lead.expectedDateToCloseDeal || ''
        this.details.chanceToBecomeDeal = this.lead.chanceToBecomeDeal || 0
        this.details.chanceToCloseDeal = this.lead.chanceToCloseDeal || 0
        this.details.id = this.lead.id
        this.details.candidancy = this.lead.candidancy
        this.details.candidancy_status = this.lead.candidancy_status
        this.details.candidateWinner = this.lead.candidateWinner
        this.details.entryDatePlaced = this.lead.entryDatePlaced
        this.details.fileContract = ''
        this.details.contract = this.lead.contract || null
        this.details.leadStep = this.lead.leadStep || null
        this.details.leadStepDateCreated = this.lead.leadStepDateCreated || null
        this.details.businessStep = this.lead.businessStep || null
        this.details.businessStepDateCreated =
          this.lead.businessStepDateCreated || null
        this.details.projectStep = this.lead.projectStep || null
        this.details.projectStepDateCreated =
          this.lead.projectStepDateCreated || null
        this.details.placedStep = this.lead.placedStep || null
        this.details.placedStepDateCreated =
          this.lead.placedStepDateCreated || null

        this.contactsIds = this.details.contacts.map((contact) => contact.id)

        if (this.details.companyId) {
          this.getCompanyById(this.details.companyId)
        }
        this.setSnapshot(this.details)

        this.drawer = true

        if (this.currentStage === 5) {
          this.getDirRoot({ name: 'Contratos' })
        }
      })

      this.loadPipelineActivities(this.item.pipelineId)
    },
    close () {
      const mirror = Object.assign(this.details)
      mirror.contacts.forEach((element) => {
        delete element.companies
        delete element.status
        delete element.linkedin
        delete element.observation
      })
      this.openIfChanged({
        data: mirror,
        save: this.save,
        callback: () => {
          this.clearForm()
          this.drawer = false
        }
      })
    },
    cancel () {
      this.openIfChanged({
        data: this.details,
        save: this.save,
        callback: () => {
          this.setCurrentPipeline(this.item.pipelineId)
          this.loadLead(this.item.pipelineId).then(() => {
            this.details.companyId = this.lead.companyId || ''
            this.details.code = this.lead.code
            this.details.contacts = this.lead.contacts || []
            this.details.prospectStatusId = this.lead.prospectStatusId || ''
            this.details.leadStatusId = this.lead.leadStatusId || ''
            this.details.businessStatusId = this.lead.businessStatusId || ''
            this.details.projectStatusId = this.lead.projectStatusId || ''
            this.details.placedStatusId = this.lead.placedStatusId || ''
            this.details.monitoringFraquencyId =
              this.lead.monitoringFraquencyId || ''
            this.details.internalFupPriorityId =
              this.lead.internalFupPriorityId || ''
            this.details.customerFupPriorityId =
              this.lead.customerFupPriorityId || ''
            this.details.originId = this.lead.originId || ''
            this.details.responsible = this.lead.responsible
            this.details.pipelineServiceTypeId =
              this.lead.pipelineServiceTypeId || ''
            this.details.name = this.lead.name
            this.details.note = this.lead.note
            this.details.businessRejectStatusId =
              this.lead.businessRejectStatusId || ''
            this.details.expectedDateToCloseDeal =
              this.lead.expectedDateToCloseDeal || ''
            this.details.chanceToBecomeDeal = this.lead.chanceToBecomeDeal || 0
            this.details.chanceToCloseDeal = this.lead.chanceToCloseDeal || 0
            this.details.id = this.lead.id
            this.details.candidancies = this.lead.candidancies
            this.details.candidancy = this.lead.candidancy
            this.details.candidancy_status = this.lead.candidancy_status
            this.details.candidateWinner = this.lead.candidateWinner
            this.details.entryDatePlaced = this.lead.entryDatePlaced
            this.details.fileContract = ''
            this.details.contract = this.lead.contract || null
            this.details.leadStep = this.lead.leadStep || null
            this.details.leadStepDateCreated =
              this.lead.leadStepDateCreated || null
            this.details.businessStep = this.lead.businessStep || null
            this.details.businessStepDateCreated =
              this.lead.businessStepDateCreated || null
            this.details.projectStep = this.lead.projectStep || null
            this.details.projectStepDateCreated =
              this.lead.projectStepDateCreated || null
            this.details.placedStep = this.lead.placedStep || null
            this.details.placedStepDateCreated =
              this.lead.placedStepDateCreated || null

            this.contactsIds = this.details.contacts.map(
              (contact) => contact.id
            )

            this.setSnapshot(this.details)

            this.drawer = true
          })

          this.loadPipelineActivities(this.item.pipelineId)
        }
      })
    },
    openUser (user) {
      if (!user) return
      this.$emit('openUser', user)
    },
    clearForm () {
      this.details.new = ''
      this.details.companyId = null
      this.details.code = null
      this.details.contacts = []
      this.details.prospectStatusId =
        this.currentStep === this.stage.PROSPECT ? '' : 1
      this.details.leadStatusId = ''
      this.details.businessStatusId = ''
      this.details.projectStatusId = ''
      this.details.placedStatusId = ''
      this.details.monitoringFraquencyId = ''
      this.details.originId =
        this.currentStep === this.stage.PROSPECT ? this.prospectOriginId : ''
      this.details.responsible = null
      this.details.pipelineServiceTypeId = ''
      this.details.internalFupPriorityId = ''
      this.details.customerFupPriorityId = ''
      this.details.name = ''
      this.details.note = ''
      this.details.businessRejectStatusId = ''
      this.details.expectedDateToCloseDeal = null
      this.details.chanceToBecomeDeal = 0
      this.details.chanceToCloseDeal = 0
      this.details.id = null
      this.details.candidancy = []
      this.details.candidancy_status = ''
      this.details.candidateWinner = null
      this.details.entryDatePlaced = null
      this.details.fileContract = ''
      this.details.contract = null
      this.details.leadStep = null
      this.details.leadStepDateCreated = null
      this.details.businessStep = null
      this.details.businessStepDateCreated = null
      this.details.projectStep = null
      this.details.projectStepDateCreated = null
      this.details.placedStep = null
      this.details.placedStepDateCreated = null

      this.contactsIds = []
      this.setSnapshot(this.details)

      this.setCurrentCompany({})
      this.resetData()
    },
    changePipelineContacts (contactId) {
      if (this.contactsIds.includes(contactId)) {
        this.contactsIds = this.contactsIds.filter((id) => id !== contactId)
        return
      }

      this.contactsIds = [...this.contactsIds, contactId]
    },
    async save () {
      if (!this.currentCompany) {
        this.showSnackbar({
          message: 'Campo empresa obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }

      if (!this.details.prospectStatusId && this.currentStage === 1) {
        this.showSnackbar({
          message: 'Campo status obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }
      if (!this.details.leadStatusId && this.currentStage === 2) {
        this.showSnackbar({
          message: 'Campo status obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }

      if (!this.details.businessStatusId && this.currentStage === 3) {
        this.showSnackbar({
          message: 'Campo status obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }
      if (!this.details.pipelineServiceTypeId && this.currentStage === 3) {
        this.showSnackbar({
          message: 'Campo tipo de serviço obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }

      if (!this.details.name && this.currentStage === 3) {
        this.showSnackbar({
          message: 'Campo posição obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }

      if (!this.details.originId && this.currentStage <= 3) {
        this.showSnackbar({
          message: 'Campo origem obrigatório para o cadastro.',
          secondsToClose: 5,
          type: 'warn'
        })
        return
      }

      if (this.currentStage === 3) {
        if (
          !this.details.originId &&
          !this.details.name &&
          !this.details.companyId &&
          !this.details.note &&
          !this.details.businessStatusId
        ) {
          this.showSnackbar({
            message:
              'Campos Origem, empresa, posição, tipo de serviço, status, prioridade obrigatório para o cadastro.',
            secondsToClose: 5,
            type: 'warn'
          })
          return
        }
      }

      this.details.companyId = this.currentCompany.id

      const leadSetAsBusiness =
        this.currentStage === this.stage.LEAD && this.details.leadStatusId === 5

      const stepId = leadSetAsBusiness ? 3 : this.currentStage

      if (leadSetAsBusiness) {
        this.details.leadStatusId = 5
        this.details.businessStatusId = 1
      }

      await this.savePosition({
        ...this.details,
        stepId
      })
        .then((result) => {
          if (this.currentStage === 5) {
            // Salva contrato caso for status placed
            this.saveContract()
          }
          this.drawer = false
          if (!result) return
          this.showSnackbar({
            message: 'Dados salvos com sucesso',
            secondsToClose: 5,
            type: 'success'
          })
          if (result?.id) this.details.id = result.id
        })
        .then(() => this.setSnapshot(this.details))

      if (leadSetAsBusiness) this.changeActivePhase(3)

      if (
        (this.currentStage === this.stage.BUSINESS ||
          this.currentStage === this.stage.PROJECTS) &&
        this.tab === 'financeiro'
      ) {
        await this.createFinancial(this.item.pipelineId)
      }

      if (
        (this.currentStage === this.stage.BUSINESS ||
          this.currentStage === this.stage.PROJECTS) &&
        (this.item.pipelineId || this.details.id)
      ) {
        const pipelineId = this.item?.pipelineId || this.details?.id
        await this.updateJob(pipelineId).then(() =>
          this.showSnackbar({
            message: 'Dados salvos com sucesso',
            secondsToClose: 5,
            type: 'success'
          })
        )
        this.drawer = false
      }
    },
    reloadPositions (stage) {
      if (stage === this.stage.LEAD) {
        return this.getLeads().catch(() =>
          this.showSnackbar({
            message: 'Falha ao recuperar leads',
            secondsToClose: 5,
            type: 'error'
          })
        )
      }

      if (stage === this.stage.BUSINESS) {
        return this.getBusiness().catch(() =>
          this.showSnackbar({
            message: 'Falha ao recuperar negócios',
            secondsToClose: 5,
            type: 'error'
          })
        )
      }

      this.getProspects().catch(() =>
        this.showSnackbar({
          message: 'Falha ao recuperar prospects',
          secondsToClose: 5,
          type: 'error'
        })
      )
    },
    reloadCards () {
      this.getFavorites(this.currentStep);
      switch (this.currentStep) {
        case 1: this.getProspects(); break;
        case 2: this.getLeads(); break;
        case 3: this.getBusiness(); break;
        case 4: this.getProjects(); break;
        case 5: this.getPlaceds(); break;
        default: break;
      }
    },
    toggleFavorite () {
      console.log("click favorite", this.userFavorites, this.details.id);
      const isFavorite = this.userFavorites.includes(this.details.id)

      if (isFavorite) this.removeFavorite([this.details.id])
      else this.addFavorites([this.details.id])

      setTimeout(() => {
        this.reloadCards();
      }, 200);

    },
    async saveContract () {
      const formData = new FormData()
      formData.append('file', this.details.fileContract)
      formData.append('folderIdSharePoint', this.currentFolder.id)
      formData.append('pipelineId', this.details.id)
      formData.append('contactId', this.details.candidancy[0].contactId)
      await this.saveFileContract(formData)
    }
  }
}
</script>
