import moment from 'moment'
export default {
  namespaced: true,
  state: {
    schooling: [],
    schoolingType: [],
    skills: [],
    softSkills: [],
    language: [],
    languageLevel: [],
    languageRequirement: [],
    jobLevel: [],
    currentPipeline: {},
    currentApproach: '',
    candidates: [],
    companyName: [],
    pipelineName: [],
    currentCandidate: {},
    currentTab: '',
    candidatesList: [],
    selectedContacts: [],
    contactsReference: [],
    relationshipStatus: [],
    viewLoadMoreCandidates: true,
    countCandidates: 0,
    form: {
      addresses: [],
      description: '',
      jobLevelId: '',
      experience: '',
      responsibility: '',
      challenges: '',
      languages: [],
      skills: [],
      softSkills: [],
      schooling: [],
      deliveries: '',
      approachScript: '',
      interviewText: '',
      interviewAvoidText: '',
      salary: '',
      bonus: '',
      bonusPeriodicity: '',
      hasOtherBonus: false,
      otherBonusDescription: '',
      hasBenefits: false,
      benefitsDescription: '',
      pairs: [],
      teams: [],
      dealValue: '',
      purchaseOrder: '',
      valueHistory: ''
    }
  },
  getters: {
    schooling: (state) => state.schooling,
    schoolingType: (state) => state.schoolingType,
    skills: (state) => state.skills,
    softSkills: (state) => state.softSkills,
    language: (state) => state.language,
    languageLevel: (state) => state.languageLevel,
    languageRequirement: (state) => state.languageRequirement,
    jobLevel: (state) => state.jobLevel,
    currentPipeline: (state) => state.currentPipeline,
    candidates: (state) => state.candidates,
    companyName: (state) => state.companyName,
    pipelineName: (state) => state.pipelineName,
    currentCandidate: (state) => state.currentCandidate,
    currentTab: (state) => state.currentTab,
    candidatesList: (state) => state.candidatesList,
    selectedContacts: (state) => state.selectedContacts,
    contactsReference: (state) => state.contactsReference,
    relationshipStatus: (state) => state.relationshipStatus,
    viewLoadMoreCandidates: (state) => state.viewLoadMoreCandidates,
    countCandidates: (state) => state.countCandidates,
  },
  mutations: {
    SET_COUNT_CANDIDATES (state, data) {
      state.countCandidates = data
    },
    APPEND_SKILL (state, data) {
      state.skills = [...state.skills, data]
    },
    APPEND_SOFT_SKILL (state, data) {
      state.softSkills = [...state.softSkills, data]
    },
    SET_SCHOOLING (state, data) {
      state.schooling = data
    },
    SET_SCHOOLING_TYPE (state, data) {
      state.schoolingType = data
    },
    SET_SKILLS (state, data) {
      state.skills = data
    },
    SET_SOFT_SKILLS (state, data) {
      state.softSkills = data
    },
    SET_LANGUAGE (state, data) {
      state.language = data
    },
    SET_LANGUAGE_LEVEL (state, data) {
      state.languageLevel = data
    },
    SET_LANGUAGE_REQUIREMENT (state, data) {
      state.languageRequirement = data
    },
    SET_JOB_LEVEL (state, data) {
      state.jobLevel = data
    },
    SET_FORM_ADRESSESS (state, data) {
      state.form.addresses = data
    },
    SET_FORM_DESCRIPTION (state, data) {
      state.form.description = data
    },
    SET_FORM_JOB_LEVEL_ID (state, data) {
      state.form.jobLevelId = data
    },
    SET_FORM_EXPERIENCE (state, data) {
      state.form.experience = data
    },
    SET_FORM_RESPONSIBILITY (state, data) {
      state.form.responsibility = data
    },
    SET_FORM_CHALLENGES (state, data) {
      state.form.challenges = data
    },
    SET_FORM_LANGUAGES (state, data) {
      state.form.languages = data
    },
    SET_FORM_SKILLS (state, data) {
      state.form.skills = data
    },
    SET_FORM_SOFT_SKILLS (state, data) {
      state.form.softSkills = data
    },
    SET_FORM_SCHOOLING (state, data) {
      state.form.schooling = data
    },
    SET_FORM_DELIVERIES (state, data) {
      state.form.deliveries = data
    },
    SET_FORM_APPROACH_SCRIPT (state, data) {
      state.form.approachScript = data
    },
    SET_FORM_INTERVIEW_TEXT (state, data) {
      state.form.interviewText = data
    },
    SET_FORM_INTERVIEW_AVOID_TEXT (state, data) {
      state.form.interviewAvoidText = data
    },
    SET_FORM_SALARY (state, data) {
      state.form.salary = data
    },
    SET_FORM_BONUS (state, data) {
      state.form.bonus = data
    },
    SET_FORM_BONUS_PERIODICITY (state, data) {
      state.form.bonusPeriodicity = data
    },
    SET_FORM_HAS_OTHER_BONUS (state, data) {
      state.form.hasOtherBonus = data
    },
    SET_FORM_OTHER_BONUS_DESCRIPTION (state, data) {
      state.form.otherBonusDescription = data
    },
    SET_FORM_HAS_BENEFITS (state, data) {
      state.form.hasBenefits = data
    },
    SET_FORM_BENEFITS_DESCRIPTION (state, data) {
      state.form.benefitsDescription = data
    },
    SET_FORM_PAIRS (state, data) {
      state.form.pairs = data
    },
    SET_FORM_TEAMS (state, data) {
      state.form.teams = data
    },
    SET_CURRENT_PIPELINE (state, data) {
      state.currentPipeline = data
    },
    SET_CANDIDATES (state, data) {
      state.candidates = data
    },
    SET_COMPANY_NAME (state, data) {
      state.companyName = data
    },
    SET_PIPELINE_NAME (state, data) {
      state.pipelineName = data
    },
    APPEND_CANDIDATES (state, data) {
      state.candidates = state.candidates.concat(data)
    },
    SET_CURRENT_CANDIDATE (state, data) {
      state.currentCandidate = data
    },
    SET_CURRENT_TAB (state, data) {
      state.currentTab = data
    },
    SET_CANDIDATES_LIST (state, data) {
      state.candidatesList = data
    },
    SET_SELECTED_CONTACTS (state, contacts) {
      state.selectedContacts = contacts
    },
    CLEAR_CONTACTS (state) {
      state.selectedContacts = []
    },
    CLEAR_CANDIDATES (state) {
      state.candidates = []
    },
    SET_FORM_FINANCIAL_VALUEDEAL (state, data) {
      state.form.dealValue = data
    },
    SET_FORM_FINANCIAL_PURCHASE_ORDER (state, data) {
      state.form.purchaseOrder = data
    },
    SET_CONTACTS_REFERENCE (state, data) {
      state.contactsReference = data
    },
    SET_FORM_FINANCIAL_VALUE_HISTORY (state, data) {
      state.form.valueHistory = data
    },
    SET_RELATIONSHIP_STATUS (state, data) {
      state.relationshipStatus = data
    },
    SET_VIEW_LOAD_MORE (state, data) {
      state.viewLoadMoreCandidates = data
    }
  },
  actions: {
    resetData ({ commit }) {
      commit('SET_FORM_ADRESSESS', [])
      commit('SET_FORM_DESCRIPTION', '')
      commit('SET_FORM_JOB_LEVEL_ID', '')
      commit('SET_FORM_EXPERIENCE', '')
      commit('SET_FORM_RESPONSIBILITY', '')
      commit('SET_FORM_CHALLENGES', '')
      commit('SET_FORM_LANGUAGES', [])
      commit('SET_FORM_SKILLS', [])
      commit('SET_FORM_SOFT_SKILLS', [])
      commit('SET_FORM_SCHOOLING', [])
      commit('SET_FORM_DELIVERIES', '')
      commit('SET_FORM_APPROACH_SCRIPT', '')
      commit('SET_FORM_INTERVIEW_TEXT', '')
      commit('SET_FORM_INTERVIEW_AVOID_TEXT', '')
      commit('SET_FORM_SALARY', '')
      commit('SET_FORM_BONUS', '')
      commit('SET_FORM_BONUS_PERIODICITY', '')
      commit('SET_FORM_HAS_OTHER_BONUS', false)
      commit('SET_FORM_OTHER_BONUS_DESCRIPTION', '')
      commit('SET_FORM_HAS_BENEFITS', false)
      commit('SET_FORM_BENEFITS_DESCRIPTION', '')
      commit('SET_FORM_PAIRS', [])
      commit('SET_FORM_TEAMS', [])
      commit('SET_FORM_FINANCIAL_VALUEDEAL', '')
      commit('SET_FORM_FINANCIAL_VALUE_HISTORY', '')
    },
    loadJobOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/pipeline/job/options')
        .then(({ data }) => {
          commit('SET_SCHOOLING', data.schooling)
          commit('SET_SCHOOLING_TYPE', data.schoolingType)
          commit('SET_SKILLS', data.skills)
          commit('SET_SOFT_SKILLS', data.softSkills)
          commit('SET_LANGUAGE', data.language)
          commit('SET_LANGUAGE_LEVEL', data.languageLevel)
          commit('SET_LANGUAGE_REQUIREMENT', data.languageRequirement)
          commit('SET_JOB_LEVEL', data.jobLevel)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar listagem de opções da vaga',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadPipelineJobData ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state

      return $axios
        .get(`/pipeline/job/${pipelineId}`)
        .then(({ data }) => {
          commit('SET_FORM_ADRESSESS', data.addresses)
          commit('SET_FORM_DESCRIPTION', data.description)
          commit('SET_FORM_JOB_LEVEL_ID', data.jobLevelId)
          commit('SET_FORM_EXPERIENCE', data.experience)
          commit('SET_FORM_RESPONSIBILITY', data.responsibility)
          commit('SET_FORM_CHALLENGES', data.challenges)
          commit('SET_FORM_LANGUAGES', data.languages)
          commit('SET_FORM_SKILLS', data.skills)
          commit('SET_FORM_SOFT_SKILLS', data.softSkills)
          commit('SET_FORM_SCHOOLING', data.schooling)
          commit('SET_FORM_DELIVERIES', data.deliveries)
          commit('SET_FORM_APPROACH_SCRIPT', data.approachScript)
          commit('SET_FORM_INTERVIEW_TEXT', data.interviewText)
          commit('SET_FORM_INTERVIEW_AVOID_TEXT', data.interviewAvoidText)
          commit('SET_FORM_SALARY', data.salary * 1000)
          commit('SET_FORM_BONUS', data.bonus * 1000)
          commit('SET_FORM_BONUS_PERIODICITY', data.bonusPeriodicity)
          commit('SET_FORM_HAS_OTHER_BONUS', data.hasOtherBonus)
          commit('SET_FORM_OTHER_BONUS_DESCRIPTION', data.otherBonusDescription)
          commit('SET_FORM_HAS_BENEFITS', data.hasBenefits)
          commit('SET_FORM_BENEFITS_DESCRIPTION', data.benefitsDescription)
          commit('SET_FORM_PAIRS', data.pairs)
          commit('SET_FORM_TEAMS', data.teams)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar informações da vaga',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createSkill ({ commit, dispatch }, name) {
      const { $axios } = this.state

      return $axios
        .post('/pipeline/skill', { name })
        .then(({ data }) => {
          if (!data.success) throw Error()

          commit('APPEND_SKILL', {
            id: data.id,
            name
          })

          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao gravar nova competência técnica',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createSkillAcademy ({ dispatch }, name) {
      const { $axios } = this.state

      return $axios
        .post('/pipeline/skill/academy', { name })
        .then(({ data }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Formação criada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )

          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao gravar nova formação',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createSoftSkill ({ commit, dispatch }, name) {
      const { $axios } = this.state

      return $axios
        .post('/pipeline/soft-skill', { name })
        .then(({ data }) => {
          if (!data.success) throw Error()

          commit('APPEND_SOFT_SKILL', {
            id: data.id,
            name
          })

          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao gravar nova competência técnica',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    updateJob ({ dispatch }, pipelineId) {
      const { $axios } = this.state

      const data = {
        experience: this.state.jobs.form.experience,
        responsibility: this.state.jobs.form.responsibility,
        challenges: this.state.jobs.form.challenges,
        bonus: this.state.jobs.form.bonus ? this.state.jobs.form.bonus : 0,
        bonusPeriodicity: this.state.jobs.form.bonusPeriodicity,
        hasOtherBonus: this.state.jobs.form.hasOtherBonus,
        otherBonusDescription: this.state.jobs.form.otherBonusDescription,
        hasBenefits: this.state.jobs.form.hasBenefits,
        benefitsDescription: this.state.jobs.form.benefitsDescription,
        description: this.state.jobs.form.description,
        deliveries: this.state.jobs.form.deliveries,
        approachScript: this.state.jobs.form.approachScript,
        interviewText: this.state.jobs.form.interviewText,
        interviewAvoidText: this.state.jobs.form.interviewAvoidText,
        salary: this.state.jobs.form.salary ? this.state.jobs.form.salary : 0,
        addresses: this.state.jobs.form.addresses,
        languages: this.state.jobs.form.languages,
        pairs: this.state.jobs.form.pairs,
        schooling: this.state.jobs.form.schooling,
        skills: this.state.jobs.form.skills,
        softSkills: this.state.jobs.form.softSkills,
        teams: this.state.jobs.form.teams
      }

      return $axios
        .put(`/pipeline/job/${pipelineId}`, data)
        .then(({ data: result }) => {
          if (!result.success)
            throw Error('Falha na atualização de dados da vaga')
          return result.success
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao gravar dados da vaga',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createFinancial ({ dispatch }, pipelineId) {
      const { $axios } = this.state
      if (!this.state.jobs.form.dealValue) return

      const data = {
        dealValue: this.state.jobs.form.dealValue,
        pipelineId: pipelineId
      }

      return $axios
        .post(`/pipeline/financial`, data)
        .then(({ data: result }) => {
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao gravar dados da vaga',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    setCurrentPipeline ({ commit }, data) {
      commit('SET_CURRENT_PIPELINE', data)
    },
    loadApproachScript ({ dispatch }, data) {
      if (!data.pipelineId || !data.contactId) return
      const { $axios } = this.state
      return $axios
        .get(
          `/job/approach/pipeline/${data.pipelineId}/contact/${data.contactId}`
        )
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao consultar script de abordagem',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadInterviewScript ({ dispatch }, data) {
      if (!data.pipelineId || !data.contactId) return
      const { $axios } = this.state
      return $axios
        .get(
          `/job/interview/pipeline/${data.pipelineId}/contact/${data.contactId}`
        )
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao consultar script de entrevista',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadInterviewAvoidScript ({ dispatch }, data) {
      if (!data.pipelineId || !data.contactId) return
      const { $axios } = this.state
      return $axios
        .get(
          `/job/interviewavoid/pipeline/${data.pipelineId}/contact/${data.contactId}`
        )
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao consultar script de entrevista',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadCandidates ({ commit, dispatch }, data) {
      const { $axios } = this.state
      const parameters = []

      if (data.params) {
        if (data.params.limit) parameters.push(`limit=${data.params.limit}`)
        if (data.params.page) parameters.push(`page=${data.params.page}`)
      }

      return $axios
        .get(`/job/pipeline/${data.pipelineId}?${parameters.join('&')}`)
        .then((response) => {
          if (!response.data.length) {
            commit('SET_VIEW_LOAD_MORE', false)
            return []
          }

          if (!parameters.length) {
            commit('SET_CANDIDATES', response.data)
            if (response.data.length > 0) {
              commit('SET_COMPANY_NAME', response.data[0]?.companyName)
              commit('SET_PIPELINE_NAME', response.data[0]?.pipelineName)
            }

            return response.data
          }

          commit('APPEND_CANDIDATES', response.data)
          return response.data
        })
        .catch((error) => {
          // Remover assim que arrumar o bug ao buscar de uma seleção com 0 candidatos
          if (
            typeof error.parsedErrorText === 'string' &&
            error.parsedErrorText.includes(
              'Cannot read properties of undefined'
            )
          ) {
            commit('SET_CANDIDATES', [])
            return
          }
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao listar candidatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    setCurrentCandidate ({ commit }, data) {
      commit('SET_CURRENT_CANDIDATE', data)
    },
    filterCandidates ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post(`/job/filter/`, {
          pipelineId: data.pipelineId,
          stars: data.stars,
          contactName: data.contactName,
          companyId: data.companyId === '' ? null : data.companyId,
          status: data.status === '' ? null : data.status
        })
        .then((response) => {
          commit('SET_CANDIDATES', response.data)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao listar candidatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    setCurrentTab ({ commit }, data) {
      commit('SET_CURRENT_TAB', data)
    },
    listCandidates ({ commit, dispatch }) {
      const { $axios } = this.state
      return []
      return $axios
        .get(`/job/list-candidates`)
        .then((response) => {
          commit('SET_CANDIDATES_LIST', response.data)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao listar candidatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    setSelectedContacts ({ commit }, data) {
      commit('SET_SELECTED_CONTACTS', data)
    },
    applicationCandidate ({ commit, dispatch }, data) {
      const { $axios } = this.state
      const dataPost = {
        pipelineId: data.pipelineId,
        contactsIds: this.state.jobs.selectedContacts,
        status: 3, //Status "tentando contato"
        star: 0,
        compatibility: 0,
        approachId: null,
        interviewScriptId: null,
        winner: null
      }
      return $axios
        .post('/job', dataPost)
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Contatos selecionados com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          dispatch('loadCandidates', {
            pipelineId: data.pipelineId
          })
          commit('SET_SELECTED_CONTACTS', [])
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao selecionar candidatos',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          commit('SET_SELECTED_CONTACTS', [])
        })
    },
    candidateWinner ({ dispatch }, data) {
      const { $axios } = this.state
      const { pipelineId, contactId, status, statusRollback, callback } = data
      return $axios
        .post('/job/winner', { pipelineId, contactId, status, statusRollback })
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Salvo com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          dispatch('loadCandidates', {
            pipelineId
          })

          if (callback && typeof callback === 'function') {
            callback()
          }
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao salvar ganhador',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    },
    candidateWinnerRoolback ({ dispatch }, data) {
      const { $axios } = this.state
      const { pipelineId, contactId, status, callback } = data
      return $axios
        .put('/job/winner', { pipelineId, contactId, status })
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Salvo com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          dispatch('loadCandidates', {
            pipelineId
          })

          if (callback && typeof callback === 'function') {
            callback()
          }
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao salvar ganhador',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    },
    updateCandidancy ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/update/${data.id}`, data)
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Salvo com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          dispatch('loadCandidates', {
            pipelineId: this.state.jobs.currentPipeline.id
          })

          let candidate = this.state.jobs.currentCandidate
          if (data.motive) {
            candidate.motive = data.motive
          }

          if (data.status) {
            candidate.status = data.status
          }

          if (data.observation) {
            candidate.observation = data.observation
          }

          dispatch('setCurrentCandidate', candidate)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao salvar',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    },
    removeCandidate ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .delete(`/job/contact/${data.contactId}/job-applicationid/${data.id}`)
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Contato removido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )

          const candidates = this.state.jobs.candidates.filter(
            (candidate) => candidate.id !== data.id
          )
          commit('SET_CANDIDATES', candidates)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao remover contato',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    },
    changeCadidateRating ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/ranking/`, {
          pipelineId: data.pipelineId,
          contactId: data.contactId,
          stars: data.stars
        })
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Ranking alterado com sucesso',
              secondsToClose: 3,
              type: 'success'
            },
            { root: true }
          )
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Erro ao mudar o ranking do candidato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadCandidancy ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .get(`/pipeline/${data.pipelineId}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar candidaturas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    matchJobCandidate ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post(`/job/matchJob`, {
          jobApplicationId: data.jobApplicationId,
          contactId: data.contactId,
          pipelineId: data.pipelineId
        })
        .then((response) => {
          const candidates = this.state.jobs.candidates.filter((candidate) => {
            if (candidate.id === data.jobApplicationId) {
              candidate.compatibility = Math.trunc(response.data)
              return true
            }
            return candidate
          })
          commit('SET_CANDIDATES', candidates)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao calcular match',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    saveReference ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post(`/job/reference`, data)
        .then((response) => {
          dispatch('loadReference', data.contactId)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Referência criada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao incluir referência',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadReference ({ commit, dispatch }, contactId) {
      const { $axios } = this.state
      return $axios
        .get(`/job/reference/${contactId}`)
        .then((response) => {
          commit('SET_CONTACTS_REFERENCE', response.data)
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao carregar contatos de refêrencia',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    removeReference ({ commit, dispatch }, id) {
      const { $axios } = this.state
      return $axios
        .delete(`/job/reference/${id}`)
        .then((response) => {
          const contacts = this.state.jobs.contactsReference.filter(
            (reference) => reference.id !== id
          )
          commit('SET_CONTACTS_REFERENCE', contacts)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Referência removida com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao remover contato de referência',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadPipelineFinancialData ({ commit, dispatch }, pipelineId) {
      if (!pipelineId) return
      const { $axios } = this.state
      return $axios
        .get(`/pipeline/financial/${pipelineId}`)
        .then(({ data }) => {
          if (!data.length) return
          let history = []
          data.forEach((item) => {
            let object = item.valuesHistories[0]
            object.name = item.purchaseOrder
            object.date = moment().diff(item.updated, 'days')
            history.push(object)
          })
          commit('SET_FORM_FINANCIAL_VALUE_HISTORY', history)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar informações do financeiro',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    updateReference ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/reference/${data.id}`, {
          jobReference: data.jobReference,
          relationshipStatusId: data.relationshipStatusId,
          contactReferenceId: data.contactReferenceId
        })
        .then((response) => {
          dispatch('loadReference', data.contactId)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Referência atualizada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao atualizar referência',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    updateApproachScript ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/approach/${data.pipelineId}`, data)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao atualizar script de abordagem',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    updateInterviewScript ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/interview/${data.pipelineId}`, data)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao atualizar script de entrevista',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    updateInterviewAvoidScript ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put(`/job/interview-avoid/${data.pipelineId}`, data)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Erro ao atualizar script de entrevista',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadCountCandidates ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state
      return $axios
        .get(`/job/count-candidates/${pipelineId}`)
        .then(({ data }) => {
          commit('SET_COUNT_CANDIDATES', data[0].total)
          return data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao contar candidatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    loadRelationshipStatus ({ commit, dispatch }) {
      const { $axios } = this.state
      return $axios
        .get(`/job/relationship-status`)
        .then(({ data }) => {
          commit('SET_RELATIONSHIP_STATUS', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar informações dos relacionamentos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    }
  }
  // eslint-disable-next-line
}
