import { toRaw } from 'vue'
export default {
  namespaced: true,
  state: {
    selectedCompany: {},
    currentCompany: {},
    companies: [],
    options: [],
    newCompany: {
      councilContacts: [],
      comercialContacts: []
    },
    selectedContacts: []
  },
  getters: {
    companies: (state) => state.companies,
    currentCompany: (state) => state.currentCompany,
    options: (state) => state.options,
    newCompany: (state) => state.newCompany,
    selectedContacts: (state) => state.selectedContacts
  },
  mutations: {
    CLEAR_CONTACTS (state) {
      state.selectedContacts = []
    },
    CLEAR_COMPANY (state, prop) {
      state.newCompany[prop] = []
    },
    CLEAR_COMPANY_ALL (state) {
      state.newCompany = {
        councilContacts: [],
        comercialContacts: []
      }
    },
    SET_CURRENT_COMPANY (state, company) {
      state.currentCompany = company
    },
    SET_COMPANIES (state, companies) {
      state.companies = companies
    },
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_CONTACT (state, contacts) {
      state.newCompany.comercialContacts = contacts
    },
    SET_COUNCIL (state, councils) {
      state.newCompany.councilContacts = councils
    },
    SET_SELECTED_CONTACTS (state, contacts) {
      state.selectedContacts = contacts
    }
  },
  actions: {
    loadCompanies ({ commit, dispatch }, parameters) {
      const { $axios } = this.state
      const params = {}

      if (parameters?.page) params.page = parameters.page

      if (parameters?.query) params.query = parameters.query

      if (parameters?.limit) params.limit = parameters.limit || 10
      // if (!parameters.refresh && state.companies.length) return

      return $axios
        .get('/company', { params })
        .then((response) => {
          if (parameters?.reload) {
            commit('SET_COMPANIES', [...response.data])
            return response.result.length
          }

          const records = toRaw(parameters.records)
          if (!records) {
            commit('SET_COMPANIES', [...response.data])
          } else {
            commit('SET_COMPANIES', [...records, ...response.data])
          }

        })
        .catch((error) => {
          console.log(error);
          if (!error || !error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar empresas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async loadCompanyByContact ({ dispatch }, idContact) {
      const { $axios } = this.state

      return $axios
        .get(`/company/listCompanyContact/${idContact}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar empresas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async saveCompany ({ dispatch }, data) {
      const { $axios } = this.state

      const isUpdate = data.id && data.id > 0
      try {
        const { id } = await $axios[isUpdate ? 'put' : 'post'](
          isUpdate ? `/company/${data.id}` : '/company',
          data
        )
          .then(({ data: result }) => result)
          .catch((error) => {
            if (!error.parsedErrorText) console.error(error)

            dispatch(
              'snackbar/showSnackbar',
              {
                message: error.parsedErrorText || 'Falha ao salvar empresa',
                secondsToClose: 5,
                type: 'error'
              },
              { root: true }
            )

            return Promise.reject(error)
          })

        await dispatch('loadCompanies', true)

        return { success: true, id }
      } catch (error) {
        const message = error.response.data.message
        return { success: false, message }
      }
    },
    loadOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      $axios
        .get('/company/options')
        .then((response) => {
          commit('SET_OPTIONS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar opções de empresas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async getCompanyById ({ commit, dispatch }, companyId) {
      const { $axios } = this.state
      if (typeof companyId === 'undefined' || !companyId) return
      $axios
        .get(`company/${companyId}`)
        .then((response) => {
          commit('SET_COMPANIES', [response.data])
          commit('SET_CURRENT_COMPANY', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao empresa especificada',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    addContactsToCompany ({ commit }, data) {
      data.type === 'contact'
        ? commit('SET_CONTACT', data.contatos)
        : commit('SET_COUNCIL', data.council)
    },
    setCurrentCompany ({ commit }, data) {
      commit('SET_CURRENT_COMPANY', data)
    },
    setSelectedContacts ({ commit }, data) {
      commit('SET_SELECTED_CONTACTS', data)
    },
    clearContacts ({ commit }) {
      commit('SET_SELECTED_CONTACTS')
    },
    clearCompany ({ commit }, data) {
      commit('CLEAR_COMPANY', data)
    },
    updateRole ({ state }, data) {
      const { recordId, contactId, type, role } = data

      const contactsKey =
        type === 'council' ? 'councilContacts' : 'comercialContacts'
      const contactIdentifierKey =
        type === 'council' ? 'companyCouncilId' : 'companyContactId'

      const [record] = state.newCompany[contactsKey].filter((contact) => {
        if (recordId) return contact[contactIdentifierKey] === recordId
        return contact.id === contactId
      })

      if (!record) return

      record.roleInCompany = role
    },
    updateContactRole ({ dispatch, rootState }, data) {
      const { $axios } = rootState
      const { companyId, recordId, role } = data

      $axios
        .put(`/company/${companyId}/contact/${recordId}`, { role })
        .then(({ data: result }) => {
          if (!result.success) throw new Error('Falha ao atualizar cargo')

          dispatch('updateRole', { recordId, type: 'comercial', role })

          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar cargo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    updateCouncilRole ({ dispatch, rootState }, data) {
      const { $axios } = rootState
      const { companyId, recordId, role } = data

      $axios
        .put(`/company/${companyId}/council/${recordId}`, { role })
        .then(({ data: result }) => {
          if (!result.success) throw new Error('Falha ao atualizar cargo')

          dispatch('updateRole', { recordId, type: 'council', role })

          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar cargo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async removeBond ({ dispatch }, data) {
      const { $axios } = this.state
      const { companyCouncilId, companyContactId } = data
      return $axios
        .put(`/company/removeContact/${companyCouncilId}/${companyContactId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Contato desvinculado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao desvincular contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async deleteCompany ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .delete(`/company/${data.companyId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Empresa excluida com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Erro ao desvincular empresa',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    },
    /*setCompanies ({ commit }, data) {
      commit("SET_COMPANIES", data);
    }*/
  }
  // eslint-disable-next-line
}
