<template>
  <div class="candidate-list" :class="{ active: show }">
    <Layout>
      <Toolbar>
        <template #left>
          <button class="btn text icon" @click="close()">
            <em class="fal fa-arrow-left-to-line"></em>
          </button>
          <div class="toolbar-icon">
            <em class="fal fa-users"></em>
          </div>
          <div class="toolbar-title">Seleção</div>
          <div class="badge md bg-coral">{{ countCandidates }}</div>
        </template>

        <template #right> </template>
      </Toolbar>
      <div class="toolbar empresa">
        <div class="toolbar-left">
          <div class="">
            <strong>{{ companyName }} -</strong>
            {{ pipelineName }}
          </div>
        </div>
        <div class="toolbar-right">
          <button square-user class="btn btn-indigo sm" @click="callDrawer('detalhes')">
            <em class="fal fa-square-user"></em>
            <span>Dados da vaga</span>
          </button>
          <button file-excel :class="{ loading: loadingExport }" class="btn btn-success sm" @click="exportData">
            <em class="fal fa-file-excel"></em>
            <span>Extrair</span>
          </button>
        </div>
      </div>
      <div class="pt-2 additional-filters">
        <div class="tab">
          <div class="tab-header">
            <button v-for="elem in tabs" :key="elem.name" class="btn sm" :class="{ active: tab === elem.name }"
              @click="changeTab(elem.name)">
              {{ elem.label }}
            </button>
          </div>
        </div>
      </div>

      <div class="caditate-list-content">
        <div class="caditate-list-content-left">
          <Toolbar class="tollbar-candidancy">
            <template #left></template>

            <template #right>
              <div class="toolbar-right second-toolbar">
                <b-textfield v-model="filter" placeholder="Buscar" name="search" type="search"
                  append-icon="fal fa-search" class="sm mb-0" />
                <b-autocomplete @change="order" placeholder="Ordenar por" :options="[
                  { type: 'asc', name: 'A-Z' },
                  { type: 'desc', name: 'Z-A' }
                ]" option-text="name" option-value="type" class="sm mb-0" icon="sort" />
                <b-select @change="orderStatus" placeholder="Status" :options="statusData"
                  option-text="status_description" option-value="id" class="sm mb-0" icon="sort" v-model="status" />
                <b-dropdown x="right" size="md">
                  <template #trigger="{ on: menu }">
                    <b-tooltip x="right">
                      <template #activator="{ on: tooltip }">
                        <button class="btn icon sm" v-on="{ ...tooltip, ...menu }">
                          <em class="fal fa-bars-filter"></em>
                        </button>
                      </template>
                      Filtros
                    </b-tooltip>
                  </template>

                  <template #content>
                    <div class="bg-contrast" @click.stop="">
                      <div class="bg-default pa-2">
                        <div class="subheader md">Filtros</div>
                      </div>
                      <span class="divider"></span>
                      <div class="filter-body">
                        <span>Ao menos:</span>
                        <div class="filter-box">
                          <Rating :value="stars" large @change="stars = $event" />
                        </div>
                      </div>
                      <span class="divider"></span>
                      <div class="d-flex justify-end pa-2 gap-16">
                        <button class="btn sm outlined" @click="resetFilter()">
                          Limpar
                        </button>
                        <button class="btn sm btn-coral" @click="filterCandidate()">
                          Aplicar
                        </button>
                      </div>
                    </div>
                  </template>
                </b-dropdown>
                <button class="btn icon sm btn-coral" @click="openNovoCandidato">
                  <em class="fal fa-plus"></em>
                </button>
              </div>
            </template>
          </Toolbar>
          <span class="divider"></span>
          <Transition class="candidate-list-transition" name="slide-fade" mode="out-in">
            <div>
              <div v-if="tab === 'candidatos'">
                <Candidatos :order="orderBy" :filter="filter" :orderByStatus="orderByStatus" />
              </div>
              <div v-if="tab === 'abordagem'">
                <Abordagem />
              </div>
              <div v-if="tab === 'roteiro'">
                <Roteiro />
              </div>
              <div v-if="tab === 'historico'" class="pa-3">
                <Historico />
              </div>
            </div>
          </Transition>
        </div>
        <div class="caditate-list-content-right">
          <CandidateDrawer ref="candidateDrawer" @openUser="openUserModal" @closeCandidateList="close"
            @nova-referencia="openNovaReferencia" @novoContato="openNovoContato" />
        </div>
      </div>

      <NovoCandidatoModal ref="novoCandidatoModal" />
      <NovaReferenciaModal ref="novaReferenciaModal" />
      <NovoContatoModal ref="novoContatoModal" />
    </Layout>
  </div>
</template>

<script>
import Layout from '@/layouts/default.vue'
import Toolbar from '@/components/Toolbar.vue'
import Candidatos from './Candidatos.vue'
import Abordagem from './Abordagem.vue'
import Roteiro from './Roteiro.vue'
import Historico from './Historico.vue'
import NovoCandidatoModal from './modals/novo-candidato.vue'
import NovaReferenciaModal from './modals/nova-referencia.vue'
import CandidateDrawer from './drawers/CandidatesDrawer.vue'
import Rating from './ui/Rating.vue'
import NovoContatoModal from './modals/novo-contato.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce } from '../helpers'

export default {
  name: 'CandidateList',
  components: {
    Layout,
    Toolbar,
    Candidatos,
    Abordagem,
    Roteiro,
    NovoCandidatoModal,
    NovaReferenciaModal,
    CandidateDrawer,
    Rating,
    Historico,
    NovoContatoModal
  },
  props: {},
  emits: ['openDrawer'],
  data () {
    return {
      show: false,
      candidateDrawer: false,
      tab: 'candidatos',
      filter: '',
      orderBy: '',
      orderByStatus: null,
      status: null,
      company: null,
      stars: null,
      candidatesCopy: [],
      loadingExport: false,
      status: null,
      tabs: [
        {
          name: 'candidatos',
          label: 'Candidatos'
        },
        {
          name: 'abordagem',
          label: 'Abordagem'
        },
        {
          name: 'roteiro',
          label: 'Roteiro de entrevista'
        },
        {
          name: 'historico',
          label: 'Histórico'
        }
      ],
      statusData: [
        {
          id: 1,
          status_description: 'Entrevistado Plongê',
          label: 'normal'
        },
        {
          id: 2,
          status_description: 'Indicação cliente',
          label: 'referral'
        },
        {
          id: 3,
          status_description: 'Tentando contato',
          label: 'warning'
        },
        {
          id: 4,
          status_description: 'Sem interesse',
          label: 'warning'
        },
        {
          id: 5,
          status_description: 'Não aprovado',
          label: 'rejected'
        },
        {
          id: 6,
          status_description: 'Em contato',
          label: 'normal'
        },
        {
          id: 7,
          status_description: 'Abordado',
          label: 'normal'
        },
        {
          id: 8,
          status_description: 'Apresentado',
          label: 'normal'
        },
        {
          id: 9,
          status_description: 'Entrevistado cliente',
          label: 'normal'
        },
        {
          id: 10,
          status_description: 'Off Limits cliente',
          label: 'normal'
        },
        {
          id: 11,
          status_description: 'Proposta enviada',
          label: 'normal'
        },
        {
          id: 12,
          status_description: 'Proposta rejeitada',
          label: 'normal'
        },
        {
          id: 13,
          status_description: 'Placed',
          label: 'normal'
        },
        {
          id: 14,
          status_description: 'Não abordado',
          label: 'normal'
        },
        {
          id: 15,
          status_description: 'Aprovado',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.status_description.toLowerCase()
        const nameB = b.status_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    }
  },
  watch: {
    filter: debounce(function () {
      if (this.candidatesCopy.length === 0)
        this.candidatesCopy = [...this.candidates]

      if (this.filter) {
        this.filterCandidate()
      } else {
        this.setCandidates(this.candidatesCopy)
      }
    }, 500)
  },
  computed: {
    ...mapGetters({
      currentPipeline: 'jobs/currentPipeline',
      candidates: 'jobs/candidates',
      companyName: 'jobs/companyName',
      pipelineName: 'jobs/pipelineName',
      currentCandidate: 'jobs/currentCandidate',
      countCandidates: 'jobs/countCandidates'
    })
  },
  methods: {
    ...mapActions({
      setCurrentCandidate: 'jobs/setCurrentCandidate',
      filterCandidates: 'jobs/filterCandidates',
      setCurrentContact: 'contacts/setCurrentContact',
      setCurrentHistory: 'history/setCurrent',
      exportCandidates: 'relets/candidatesByPipeline'
    }),
    ...mapMutations({
      clearCandidates: 'jobs/CLEAR_CANDIDATES',
      setViewLoadMore: 'jobs/SET_VIEW_LOAD_MORE',
      setCandidates: 'jobs/SET_CANDIDATES'
    }),
    open () {
      this.show = true
    },
    close () {
      this.setCurrentCandidate({})
      this.setCurrentContact({})
      this.clearCandidates()
      this.setViewLoadMore(true)
      this.show = false
      this.tab = 'candidatos'
    },
    changeTab (tab) {
      if (tab === 'historico') {
        this.setCurrentHistory({
          positionId: this.currentPipeline.id,
          contactId: this.currentCandidate.contactId,
          component: 'geral'
        })
      }
      this.tab = tab
    },
    openNovaReferencia () {
      this.$refs.novaReferenciaModal.openNovaReferencia()
    },
    openNovoCandidato () {
      this.$refs.novoCandidatoModal.openNovoCandidato()
    },
    openNovoContato () {
      this.$refs.novoContatoModal.open()
    },
    closeCandidateDrawer () {
      this.setCurrentCandidate({})
      this.setCurrentContact({})
      this.candidateDrawer = false
    },
    callDrawer (tab) {
      this.$emit('openDrawer', {
        color: 'esmeralda',
        icon: 'folder-user',
        name: 'Negócios',
        tab,
        pipelineId: this.currentPipeline.id
      })
    },
    async filterCandidate () {
      await this.filterCandidates({
        pipelineId: this.currentPipeline.id,
        companyId: this.company,
        stars: this.stars,
        contactName: this.filter,
        status: this.orderByStatus
      })
    },
    async resetFilter () {
      this.stars = null
      this.status = null
      this.filter = ''
      await this.filterCandidates({
        pipelineId: this.currentPipeline.id,
        companyId: null,
        stars: null,
        contactName: null,
        status: null
      })
    },
    order (event) {
      if (event.target.value === 'A-Z') {
        this.orderBy = 'asc'
      } else {
        this.orderBy = 'desc'
      }
    },
    async orderStatus (status) {
      switch (status) {
        case 1:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 1
          })
          return (this.orderByStatus = 1)
        case 2:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 2
          })
          return (this.orderByStatus = 2)
        case 3:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 3
          })
          return (this.orderByStatus = 3)
        case 4:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 4
          })
          return (this.orderByStatus = 4)
        case 5:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 5
          })
          return (this.orderByStatus = 5)
        case 6:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 6
          })
          return (this.orderByStatus = 6)
        case 7:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 7
          })
          return (this.orderByStatus = 7)
        case 8:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 8
          })
          return (this.orderByStatus = 8)
        case 9:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 9
          })
          return (this.orderByStatus = 9)
        case 10:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 10
          })
          return (this.orderByStatus = 10)
        case 11:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 11
          })
          return (this.orderByStatus = 11)
        case 12:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 12
          })
          return (this.orderByStatus = 12)
        case 13:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 13
          })
          return (this.orderByStatus = 13)
        case 14:
          await this.filterCandidates({
            pipelineId: this.currentPipeline.id,
            companyId: this.company,
            stars: this.stars,
            contactName: this.filter,
            status: 14
          })
          return (this.orderByStatus = 14)
        default:
          return null
      }
    },
    openUserModal (data) {
      this.$emit('openUser', {
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        photo: data.photo
      })
    },
    exportData () {
      this.loadingExport = true
      this.exportCandidates(this.currentPipeline.id).then(() => {
        this.loadingExport = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes entrance {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tollbar-candidancy {
  height: auto;
}

.filter-body {
  display: flex;
  flex-direction: column;
  padding: 16px;

  span {
    color: var(--light-text-default, #52526b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.filter-box {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--light-ui-border, #e1e1eb);
}

.candidate-list {
  display: none;
  top: 100%;

  &.active {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    animation: entrance 0.3s ease 0s 1 normal forwards;
  }
}

.second-toolbar {
  flex-grow: 1;
}

.caditate-list-content {
  display: flex;
  max-width: calc(100% - 60px);
  height: 100%;
  overflow: hidden;
}

.toolbar.empresa {
  max-width: calc(100% - 60px);
}

.caditate-list-content-left {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 48px;
}

.candidate-list-transition {
  overflow-y: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.caditate-list-content-left .scrollable {
  height: 600px;
}
</style>
