<template>
  <div class="empty">
    <img :src="`/images/empty/${image}.svg`" alt="" />
    <div class="empty-content">
      <div class="h1 text-color-coral">{{ title }}</div>
      <div class="paragraph sm mt-1" v-html="text"></div>
    </div>
    <div class="d-flex">
      <button class="btn btn-lavanda sm" @click="changePhase()">
        <span>Mudar para {{ button }}</span>
        <em class="fal fa-arrow-right"></em>
      </button>
      <div class="btn icon sm" :class="`btn-${color}`">
        <em class="fal" :class="`fa-${icon}`"></em>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EmptyComponent',
  props: ['image', 'title', 'text', 'button', 'icon', 'color', 'details'],
  methods: {
    ...mapActions({
      changeActivePhase: 'home/changeActivePhase',
      savePosition: 'home/savePosition',
      //getOnlySteps: 'home/getOnlySteps',
      saveLead: 'leads/saveLead',
      getOnlyBusiness: 'home/getOnlyBusiness',
      getOnlyLeads: 'home/getOnlyLeads',
      getOnlyProspects: 'home/getOnlyProspects',
      createBusiness: 'business/createBusiness'

    }),
    async changePhase () {
      const position = Object.assign({}, this.details)

      const buttonOptions = {
        Lead: 2,
        Negócio: 3
      }
      const steps = {
        Lead: 2,
        Negócio: 3
      }
      const drawers = {
        1: 'openDrawerToLead',
        2: 'openDrawerToBusiness'
      }
      const functions = {
        2: (position) => {
          // TODO precisa validar os status e mostrar mensagens de erro para Leads
          position.prospectStatusId = 1
          position.leadStatusId = 2
          this.savePosition(position)
        },
        3: (position) => {
          // TODO precisa validar os status e mostrar mensagens de erro para negocios
          position.prospectStatusId = 1
          position.leadStatusId = 5
          position.businessStatusId = 1
          this.savePosition(position)
        }
      }

      const step = position.stepId = steps[this.button]

      await functions[step](position)
      await this.getOnlyProspects()
      await this.getOnlyLeads()
      await this.getOnlyBusiness()

      this.changeActivePhase(buttonOptions[this.button])
      this.$emit('close')
      setTimeout(() => {
        const callback = drawers[step]
        if (callback) this.$emit(callback)
      }, 500)
    }
  },
  computed: {
    ...mapGetters({
      lead: 'leads/lead'
    })
  }
}
</script>
