<template>
  <div class="expansion-panel" :class="{ active }">
    <div class="expansion-panel-header" :class="'bg-' + color" @click="$emit('onClick')">
      <div class="count">{{ pipelineCount }}</div>
      <div class="content">
        <div class="icon">
          <em class="fal" :class="'fa-' + icon"></em>
        </div>
        <div class="name">{{ name }}</div>
      </div>
    </div>
    <div class="expansion-panel-content">
      <Toolbar class="md">
        <template #left>
          <div class="section-title" :class="color">
            {{ name }}
          </div>
        </template>
        <template #right>
          <b-dropdown x="right" size="md">
            <template #trigger="{ on: menu }">
              <b-tooltip x="right">
                <template #activator="{ on: tooltip }">
                  <button class="btn icon sm" v-on="{ ...tooltip, ...menu }">
                    <em class="fal fa-bars-filter"></em>
                  </button>
                </template>
                Filtros
              </b-tooltip>
            </template>
            <template #content>
              <div class="bg-contrast" @click.stop="">
                <div class="bg-default pa-2">
                  <div class="subheader md">filtrar {{ name }}</div>
                </div>
                <span class="divider"></span>
                <div class="pa-2">
                  <b-autocomplete v-model="form.status" placeholder="Selecione o status" :options="availableStatus"
                    option-text="name" option-value="id" label="Status" />
                  <b-autocomplete v-model="form.priority" placeholder="Selecione a prioridade" label="Prioridade"
                    :options="priorities" option-text="name" option-value="id" />
                </div>
                <span class="divider"></span>
                <div class="d-flex justify-end pa-2 gap-16">
                  <button class="btn sm outlined" @click="clearFilters">
                    Limpar
                  </button>
                  <button class="btn sm btn-coral" @click="filterItems">
                    Aplicar
                  </button>
                </div>
              </div>
            </template>
          </b-dropdown>
          <b-tooltip y="top">
            <template #activator="{ on }">
              <button :class="`btn icon sm ${archived ? 'btn-coral' : ''}`" @click="archived = !archived" v-on="on">
                <em class="fal fa-box-archive"></em>
              </button>
            </template>
            Arquivado
          </b-tooltip>
          <b-tooltip x="right">
            <template #activator="{ on }">
              <button class="btn icon sm" v-on="on">
                <em class="fal fa-arrow-down-wide-short"></em>
              </button>
            </template>
            Ordenar
          </b-tooltip>
          <div class="btn-group">
            <b-tooltip y="top">
              <template #activator="{ on }">
                <button :class="`btn icon sm ${visualizationType === 'list' && 'btn-coral'
                  }`" @click="changeVisualization('list')" v-on="on">
                  <em class="fal fa-list"></em>
                </button>
              </template>
              Lista
            </b-tooltip>
            <b-tooltip y="top">
              <template #activator="{ on }">
                <button :class="`btn icon sm ${visualizationType === 'grid' && 'btn-coral'
                  }`" @click="changeVisualization('grid')" v-on="on">
                  <em class="fal fa-grid"></em>
                </button>
              </template>
              Grade
            </b-tooltip>
            <b-tooltip y="top">
              <template #activator="{ on }">
                <button :class="`btn icon sm ${visualizationType === 'kanban' && 'btn-coral'
                  }`" @click="changeVisualization('kanban')" v-on="on">
                  <em class="fal fa-square-kanban"></em>
                </button>
              </template>
              Kanban
            </b-tooltip>
          </div>
          <b-tooltip x="right">
            <template #activator="{ on }">
              <button class="btn btn-lavanda icon sm" @click="openDrawerWithParams()" v-on="on">
                <em class="fal fa-plus"></em>
              </button>
            </template>
            {{ newName }}
          </b-tooltip>
        </template>
      </Toolbar>
      <div :class="`expansion-panel-content-body ${currentStage !== 4 && visualizationType === 'kanban'
        ? 'no-scrollbar expansion-panel-content-body-kanban'
        : ''
        }`">

        <div v-if="currentStage !== 4 && visualizationType === 'grid'" class="grid-cards">
          <Card v-for="item in (archived ? items.filter(q => q.filed) : items.filter(q => !q.filed))" :key="item.id"
            :item="item" :color="color" :type="name.toLowerCase()" :status="status" :archived="archived"
            @openUser="$emit('openUser', $event)" @openKickoff="$emit('openKickoff')" @openDrawer="openDrawerWithParams"
            @openProbability="$emit('openProbability', $event)" />
        </div>
        <div v-else-if="currentStage !== 4 && visualizationType === 'kanban'" class="kanban-cards">
          <div v-for="prospects in prospectItemsByStatus" :key="prospects.status.id" class="kanban-row">
            <div class="kanban-header">
              <em class="fal fa-fal fa-bullhorn"></em>
              <h2>{{ prospects.status.name }}</h2>
            </div>
            <div class="kanban-body">
              <Card v-for="item in prospects.items" :key="item.id" :item="item" :color="color"
                :type="name.toLowerCase()" :status="status" :archived="archived" @openUser="$emit('openUser', $event)"
                @openKickoff="$emit('openKickoff')" @openDrawer="openDrawerWithParams"
                @openProbability="$emit('openProbability', $event)" />
            </div>
          </div>
        </div>
        <div v-else-if="currentStage !== 4 && visualizationType === 'list'" class="list-cards">
          <div v-for="prospects in prospectItemsByStatus" :key="prospects.status.id" class="list-row">
            <div v-if="prospects.items.length">
              <div class="list-header">
                <em :class="`fal fa-fal fa-bullhorn text-color-${color}`"></em>
                <h2>{{ prospects.status.name }}</h2>
              </div>
              <div class="list-body">
                <Card v-for="item in prospects.items" :key="item.id" :item="item" :color="color"
                  :type="name.toLowerCase()" :status="status" :archived="archived" :stretch="true"
                  @openUser="$emit('openUser', $event)" @openKickoff="$emit('openKickoff')"
                  @openDrawer="openDrawerWithParams" @openProbability="$emit('openProbability', $event)" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="grid-cards">
          <ProjectsCard v-for="item in (archived ? items.filter(q => q.filed) : items.filter(q => !q.filed))"
            :key="item.id" :item="item" :color="color" :type="name.toLowerCase()" :status="status" :archived="archived"
            @openUser="$emit('openUser', $event)" @openKickoff="$emit('openKickoff')"
            @openCandidateList="openCandidateListWithParams" @openDrawer="openDrawerWithParams" />
        </div>
        <div class="dialog-content-footer justify-center">
          <b-tooltip v-if="loadMoreActive" x="right">
            <template #activator="{ on }">
              <button class="btn sm" v-on="on" @click="loadMore()">
                <em> Ver mais </em>
              </button>
            </template>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
import Toolbar from './Toolbar.vue'
import ProjectsCard from './ProjectsCard.vue'
// import CandidateList from './CandidateList.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'StatusPanel',
  components: { Toolbar, Card, ProjectsCard },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'coral'
    },
    icon: {
      type: String,
      default: 'user'
    },
    name: {
      type: String,
      default: 'Status'
    },
    count: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    status: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      stepCount: 0,
      visualizationType: 'grid',
      archived: false,
      form: {
        status: '',
        priority: ''
      },
      stage: {
        PROSPECT: 1,
        LEAD: 2,
        BUSINESS: 3,
        PROJECT: 4,
        PLACED: 5
      },
      limit: 50,
      page: 1,
      pipelineCount: 0
    }
  },
  computed: {
    ...mapGetters({
      statusHome: 'home/status',
      positionStatus: 'home/positionStatus',
      priorities: 'home/priorities'
    }),
    loadMoreActive () {
      switch (this.name) {
        case 'Placeds': {
          return (
            this.stepCount > this.limit &&
            this.statusHome[4]?.data?.length >= 50
          )
        }
        case 'Projetos': {
          return (
            this.stepCount > this.limit &&
            this.statusHome[3]?.data?.length >= 50
          )
        }
        case 'Negócios': {
          return (
            this.stepCount > this.limit &&
            this.statusHome[2]?.data?.length >= 50
          )
        }
        case 'Leads': {
          return (
            this.stepCount > this.limit &&
            this.statusHome[1]?.data?.length >= 50
          )
        }
        case 'Prospect': {
          return (
            this.stepCount > this.limit &&
            this.statusHome[0]?.data?.length >= 50
          )
        }
        default:
          return false
      }
    },
    newName () {
      const names = {
        Prospect: 'Novo prospect',
        Leads: 'Novo lead',
        Negócios: 'Novo negócio',
        Projetos: 'Novo projeto',
        Placeds: 'Novo placed'
      }
      return names[this.name] || 'Novo'
    },
    currentStage () {
      let step = 0
      switch (this.name) {
        case 'Negócios':
          step = this.stage.BUSINESS
          break
        case 'Leads':
          step = this.stage.LEAD
          break
        case 'Projetos':
          step = this.stage.PROJECT
          break
        case 'Placeds':
          step = this.stage.PLACED
          break
        case 'Prospect':
        default:
          step = this.stage.PROSPECT
      }

      return step
    },
    availableStatus () {
      if (this.currentStage === this.stage.BUSINESS) {
        return this.positionStatus.business
      }
      if (this.currentStage === this.stage.LEAD) return this.positionStatus.lead
      return this.positionStatus.prospect
    },
    prospectItemsByStatus () {
      return this.availableStatus.map((status) => {
        return {
          status,
          items: this.items.filter((item) => {
            if (this.currentStage === this.stage.BUSINESS) {
              return item.businessStatus === status.name
            } else if (this.currentStage === this.stage.PROSPECT) {
              return item.prospectStatus === status.name
            } else {
              return item.leadStatus === status.name
            }
          })
        }
      })
    }
  },
  async mounted () {
    await this.viewLoadMore()
    this.$emitter.on('viewLoadMore', this.viewLoadMore)
  },
  methods: {
    ...mapActions({
      setFilters: 'home/setFilters',
      getOnlyLeads: 'home/getOnlyLeads',
      getOnlyProspects: 'home/getOnlyProspects',
      getOnlyBusiness: 'home/getOnlyBusiness',
      getOnlyProjects: 'home/getOnlyProjects',
      getOnlyPlaceds: 'home/getOnlyPlaceds',
      getOnlyProspectsCount: 'home/getOnlyProspectsCount',
      getOnlyLeadsCount: 'home/getOnlyLeadsCount',
      getOnlyBusinessCount: 'home/getOnlyBusinessCount',
      getOnlyProjectsCount: 'home/getOnlyProjectsCount',
      getOnlyPlacedsCount: 'home/getOnlyPlacedsCount'
    }),
    changeVisualization (visualizationType) {
      // Scroll infinito para implementações futuras
      // if (visualizationType === 'kanban') {
      //   setTimeout(() => {
      //     document.querySelectorAll('.kanban-row').forEach((el) => {
      //       el.addEventListener('scrollend', () => {
      //         this.loadMore()
      //       })
      //     })
      //   }, 1000)
      // }

      this.visualizationType = visualizationType
    },
    async viewLoadMore () {
      switch (this.name) {
        case 'Negócios': {
          const { stepCounted } = await this.getOnlyBusinessCount()
          this.pipelineCount = stepCounted
          this.stepCount = stepCounted
          return stepCounted
        }
        case 'Leads': {
          const { stepCounted } = await this.getOnlyLeadsCount()
          this.pipelineCount = stepCounted
          this.stepCount = stepCounted
          return stepCounted
        }
        case 'Prospect': {
          const { stepCounted } = await this.getOnlyProspectsCount()
          this.pipelineCount = stepCounted
          this.stepCount = stepCounted
          return stepCounted
        }
        case 'Projetos': {
          const { stepCounted } = await this.getOnlyProjectsCount()
          this.pipelineCount = stepCounted
          this.stepCount = stepCounted
          return stepCounted
        }
        case 'Placeds': {
          const { stepCounted } = await this.getOnlyPlacedsCount()
          this.pipelineCount = stepCounted
          this.stepCount = stepCounted
          return stepCounted
        }
        default:
          return false
      }
    },
    openDrawerWithParams (pipelineId, tab = 'detalhes', stepId = null) {
      this.$emit('openDrawer', {
        color: this.color,
        icon: this.icon,
        name: this.name,
        tab,
        pipelineId,
        stepId
      })
    },
    openCandidateListWithParams () {
      this.$emit('openCandidateList')
    },
    clearFilters () {
      this.form.priority = ''
      this.form.status = ''

      this.filterItems()
    },
    filterItems () {
      const priorityId = this.form.priority
      const statusId = this.form.status

      this.setFilters({
        stage: this.currentStage,
        priorityId,
        statusId
      })
    },
    loadMore () {
      this.limit += 50
      switch (this.name) {
        case 'Negócios':
          this.getOnlyBusiness({ limit: this.limit, page: this.page })
          break
        case 'Leads':
          this.getOnlyLeads({ limit: this.limit, page: this.page })
          break
        case 'Prospect':
          this.getOnlyProspects({ limit: this.limit, page: this.page })
          break
        case 'Projetos':
          this.getOnlyProjects({ limit: this.limit, page: this.page })
          break
        case 'Placeds':
          this.getOnlyPlaceds({ limit: this.limit, page: this.page })
          break
        default:
          this.getOnlyProspects({ limit: this.limit, page: this.page })
          this.getOnlyLeads({ limit: this.limit, page: this.page })
          this.getOnlyBusiness({ limit: this.limit, page: this.page })
          this.getOnlyPlaceds({ limit: this.limit, page: this.page })
          break
      }
      this.viewLoadMore()
    }
  }
}
</script>
