<template>
  <div>
    <div v-if="!item.filed && !archived" ref="card" class="card cursor-pointer" :class="[
      'border-' + color,
      { disabled: item.prospectStatusId !== 1 },
      { stretch }
    ]" @click="callDrawer('detalhes', item.stepId)">
      <div class="card-content">
        <div class="card-header">
          <span v-if="item.favorite" class="favorite">
            <em class="fas fa-heart"></em>
          </span>
          <div class="card-header-left">
            <div v-if="item.code" class="code" v-text="item.code"></div>
            <div class="position-relative">
              <span class="priority" :class="priority"></span>
              <div class="highlight" v-text="item.name || '...'"></div>
              <div class="company" v-text="item.company?.name"></div>
            </div>
          </div>
          <div class="card-header-right">
            <b-dropdown>
              <template #trigger="{ on }">
                <button class="icon" v-on="on" @click="avoidDetailsOpening($event)">
                  <em class="far fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item" @click="callDrawer('detalhes', item.stepId)">
                    <em class="fal fa-circle-info"></em>
                    <span>Detalhes</span>
                  </button>
                  <button v-if="type !== 'prospect'" class="list-item" @click="callDrawer('atividades', item.stepId)">
                    <em class="fal fa-list-check"></em>
                    <span>Atividades</span>
                  </button>
                  <button class="list-item" @click="changeFiled(item.id, true)">
                    <em class="fal fa-box-archive"></em>
                    <span>Arquivar</span>
                  </button>
                  <button class="list-item" :class="{ 'text-color-coral': item.favorite }"
                    @click="favoriteItem(item.id)">
                    <em class="fal" :class="favoriteButtonData.class"></em>
                    <span>{{ favoriteButtonData.text }}</span>
                  </button>
                  <span v-if="type !== 'placeds'" class="divider"></span>
                  <button v-if="type !== 'placeds'" class="list-item" @click="changePhase">
                    <em class="fa-regular" :class="buttonNextPhase.icon"></em>
                    <span v-text="buttonNextPhase.text"></span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
        <div class="card-body">
          <div v-if="type === 'placeds'" class="counters">
            <div class="item-counter">
              <em class="fal fa-person"></em>
              <span class="text-color-light">3</span>
              <span>:</span>
              <span>124d</span>
            </div>
          </div>
          <div class="counters">
            <div v-if="item.activities.length">
              <b-tooltip>
                <template #activator="{ on }">
                  <div class="item-counter" v-on="on" @click="
                    avoidDetailsOpening(
                      $event,
                      callDrawer('atividades', item.stepId)
                    )
                    ">
                    <em class="fal fa-list-check"></em>
                    <span v-text="item.activities.length"></span>
                  </div>
                </template>
                Atividades
              </b-tooltip>
            </div>
            <div v-if="item.attachments.length">
              <b-tooltip>
                <template #activator="{ on }">
                  <div class="item-counter row-pointer" v-on="on" @click="
                    avoidDetailsOpening(
                      $event,
                      callDrawer('anexos', item.stepId)
                    )
                    ">
                    <em class="fal fa-paperclip"></em>
                    <span v-text="item.attachments.length"></span>
                  </div>
                </template>
                Anexos
              </b-tooltip>
            </div>
            <div v-if="!stretch" class="spacer">&nbsp;</div>
            <div v-if="type === 'leads' && item.chanceToBecomeDeal">
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="item-counter" v-on="on" @click.stop="$emit('openProbability', 1)">
                    <em class="fal fa-bullseye-pointer"></em>
                    {{ item.chanceToBecomeDeal }}%
                  </button>
                </template>
                Probabilidade de negócio
              </b-tooltip>
            </div>
            <div v-if="
              (type === 'negócios' || type === 'projetos') && item.contractValue
            ">
              <b-tooltip>
                <template #activator="{ on }">
                  <div class="item-counter text-color-success" v-on="on">
                    <em class="fal fa-circle-dollar"></em>
                    {{ item.contractValue }}k
                  </div>
                </template>
                Valor do negócio
              </b-tooltip>
            </div>
            <div v-if="type === 'negócios' && item.chanceToCloseDeal">
              <b-tooltip>
                <template #activator="{ on }">
                  <button class="item-counter" v-on="on" @click.stop="$emit('openProbability', 2)">
                    <em class="fal fa-bullseye-arrow"></em>
                    {{ item.chanceToCloseDeal || item.chanceToBecomeDeal }}%
                  </button>
                </template>
                Probabilidade de fechamento
              </b-tooltip>
            </div>
            <div v-if="type === 'projetos'" class="item-counter text-color-danger">
              <b-tooltip>
                <template #activator="{ on }">
                  <em class="fas fa-hexagon-exclamation" v-on="on"></em>
                </template>
                Pagamento pendente
              </b-tooltip>
            </div>
          </div>
          <div v-if="item.responsible || item.contacts.length" class="team">
            <b-tooltip v-if="item.responsible">
              <template #activator="{ on }">
                <b-avatar :name="item.responsible.name" size="xs" class="cursor-pointer with-border" v-on="on" @click="
                  avoidDetailsOpening($event, openUserModal(item.responsible))
                  ">
                  <img :src="item.responsible.photo" :alt="item.responsible.name" />
                </b-avatar>
              </template>
              {{ item.responsible.name }}
            </b-tooltip>
            <div v-if="!item.responsible && !stretch">&nbsp;</div>
            <div v-if="item.contacts.length" class="avatar-group">
              <b-tooltip v-for="(user, idx) in item.contacts" :key="idx">
                <template #activator="{ on }">
                  <b-avatar :name="user.name" class="cursor-pointer with-border" size="xs" v-on="on"
                    @click="avoidDetailsOpening($event, openUserModal(user))">
                    <img :src="user.photo" :alt="user.name" />
                  </b-avatar>
                </template>
                {{ user.name }}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="time" v-text="lastUpdate"></div>
        <div class="status" @click="avoidDetailsOpening($event, openKickoff(getStatus()))">
          <span>{{ getStatus() }}</span>
          <em class="fal" :class="[
            'fa-' + status_icon,
            { 'text-color-danger': item.status === 10 }
          ]"></em>
        </div>
      </div>


    </div>
    <div v-if="item.filed && archived" ref="card" class="card cursor-pointer"
      :class="['border-' + color, { disabled: item.prospectStatusId !== 1 }]"
      @click="callDrawer('detalhes', item.stepId)">
      <div class="card-header">
        <span v-if="item.favorite" class="favorite">
          <em class="fas fa-heart"></em>
        </span>
        <div class="card-header-left">
          <div v-if="item.code" class="code" v-text="item.code"></div>
          <div class="position-relative">
            <span class="priority" :class="priority"></span>
            <div v-if="item.name" class="font-weight-800" v-text="item.name"></div>
            <div class="company" v-text="item.company?.name"></div>
          </div>
        </div>
        <div class="card-header-right align-self-start">
          <b-dropdown>
            <template #trigger="{ on }">
              <button class="icon" v-on="on" @click="avoidDetailsOpening($event)">
                <em class="far fa-ellipsis-vertical"></em>
              </button>
            </template>
            <template #content>
              <div class="list">
                <button class="list-item" @click="callDrawer('detalhes', item.stepId)">
                  <em class="fal fa-circle-info"></em>
                  <span>Detalhes</span>
                </button>
                <button v-if="type !== 'prospect'" class="list-item" @click="callDrawer('atividades', item.stepId)">
                  <em class="fal fa-list-check"></em>
                  <span>Atividades</span>
                </button>
                <button class="list-item" @click="changeFiled(item.id, false)">
                  <em class="fal fa-box-archive"></em>
                  <span>Desarquivar</span>
                </button>
                <button class="list-item" :class="{ 'text-color-coral': item.favorite }" @click="favoriteItem(item.id)">
                  <em class="fal" :class="favoriteButtonData.class"></em>
                  <span>{{ favoriteButtonData.text }}</span>
                </button>
                <span v-if="type !== 'placeds'" class="divider"></span>
                <button v-if="type !== 'placeds'" class="list-item" @click="changePhase">
                  <em class="fa-regular" :class="buttonNextPhase.icon"></em>
                  <span v-text="buttonNextPhase.text"></span>
                </button>
              </div>
            </template>
          </b-dropdown>
        </div>
      </div>
      <div class="card-body d-flex d-flex-column justify-between">
        <div v-if="type === 'placeds'" class="counters">
          <div class="item-counter">
            <em class="fal fa-person"></em>
            <span class="text-color-light">3</span>
            <span>:</span>
            <span>124d</span>
          </div>
        </div>
        <div class="counters">
          <div v-if="item.activities.length">
            <b-tooltip>
              <template #activator="{ on }">
                <div class="item-counter" v-on="on" @click="
                  avoidDetailsOpening(
                    $event,
                    callDrawer('atividades', item.stepId)
                  )
                  ">
                  <em class="fal fa-list-check"></em>
                  <span v-text="item.activities.length"></span>
                </div>
              </template>
              Atividades
            </b-tooltip>
          </div>
          <div v-if="item.attachments.length">
            <b-tooltip>
              <template #activator="{ on }">
                <div class="item-counter row-pointer" v-on="on" @click="
                  avoidDetailsOpening(
                    $event,
                    callDrawer('anexos', item.stepId)
                  )
                  ">
                  <em class="fal fa-paperclip"></em>
                  <span v-text="item.attachments.length"></span>
                </div>
              </template>
              Anexos
            </b-tooltip>
          </div>
          <div class="spacer">&nbsp;</div>
          <div>
            <b-tooltip v-if="type === 'leads' && item.chanceToBecomeDeal">
              <template #activator="{ on }">
                <button class="item-counter" v-on="on" @click.stop="$emit('openProbability', 1)">
                  <em class="fal fa-bullseye-pointer"></em>
                  {{ item.chanceToBecomeDeal }}%
                </button>
              </template>
              Probabilidade de negócio
            </b-tooltip>
          </div>
          <div v-if="
            (type === 'negócios' || type === 'projetos') && item.contractValue
          ">
            <b-tooltip>
              <template #activator="{ on }">
                <div class="item-counter text-color-success" v-on="on">
                  <em class="fal fa-circle-dollar"></em>
                  {{ item.contractValue }}k
                </div>
              </template>
              Valor do negócio
            </b-tooltip>
          </div>
          <div v-if="type === 'negócios' && item.chanceToCloseDeal">
            <b-tooltip>
              <template #activator="{ on }">
                <button class="item-counter" v-on="on" @click.stop="$emit('openProbability', 2)">
                  <em class="fal fa-bullseye-arrow"></em>
                  {{ item.chanceToCloseDeal || item.chanceToBecomeDeal }}%
                </button>
              </template>
              Probabilidade de fechamento
            </b-tooltip>
          </div>
          <div v-if="type === 'projetos'" class="item-counter text-color-danger">
            <b-tooltip>
              <template #activator="{ on }">
                <em class="fas fa-hexagon-exclamation" v-on="on"></em>
              </template>
              Pagamento pendente
            </b-tooltip>
          </div>
        </div>
        <div class="team">
          <b-tooltip v-if="item.responsible">
            <template #activator="{ on }">
              <b-avatar :name="item.responsible.name" size="xs" class="cursor-pointer with-border" v-on="on" @click="
                avoidDetailsOpening($event, openUserModal(item.responsible))
                ">
                <img :src="item.responsible.photo" :alt="item.responsible.name" />
              </b-avatar>
            </template>
            {{ item.responsible.name }}
          </b-tooltip>
          <div v-if="!item.responsible">&nbsp;</div>
          <div class="avatar-group">
            <b-tooltip v-for="(user, idx) in item.contacts" :key="idx">
              <template #activator="{ on }">
                <b-avatar :name="user.name" class="cursor-pointer with-border" size="xs" v-on="on"
                  @click="avoidDetailsOpening($event, openUserModal(user))">
                  <img :src="user.photo" :alt="user.name" />
                </b-avatar>
              </template>
              {{ user.name }}
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="time" v-text="lastUpdate"></div>
        <div class="status" @click="avoidDetailsOpening($event, openKickoff(getStatus()))">
          <span>{{ getStatus() }}</span>
          <em class="fal" :class="[
            'fa-' + status_icon,
            { 'text-color-danger': item.status === 10 }
          ]"></em>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'
import mixins from '@/mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CardComponent',
  mixins: [mixins],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    archived: {
      type: Boolean,
      default: () => false
    },
    stretch: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'company/currentCompany',
      favorites: 'home/userFavorites',
      currentStep: 'session/currentStep'
    }),
    buttonNextPhase () {
      const data = {
        icon: '',
        text: ''
      }

      if (this.type === 'prospect') {
        data.icon = 'fa-users'
        data.text = 'Converter para lead'
      }

      if (this.type === 'leads') {
        data.icon = 'fa-badge-dollar'
        data.text = 'Converter para negócio'
      }

      if (this.type === 'negócios') {
        data.icon = 'fa-folder-user'
        data.text = 'Converter para projeto'
      }

      if (this.type === 'projetos') {
        data.icon = 'fa-user-tie'
        data.text = 'Converter para placed'
      }

      return data
    },
    type () {
      if (!this.item.stepId) return 'prospect'
      return this.stages[this.item.stepId]?.name?.toLowerCase() || 'prospect'
    },
    color () {
      return this.stages[this.item.stepId]?.color || 'lavanda'
    },
    lastUpdate () {
      if (!this.item.updated) return ''

      const updated = new Date(this.item.updated)
      const timeDifferenceInMilliseconds =
        new Date().getTime() - updated.getTime()

      const hoursDifference = Math.floor(
        timeDifferenceInMilliseconds / 1000 / 60 / 60
      )

      if (hoursDifference < 1) return 'há menos de uma hora'
      if (hoursDifference < 2) return 'há 1 hora'
      if (hoursDifference < 24) return `há ${hoursDifference} horas`
      if (hoursDifference < 48) return 'ontem'

      const daysDifference = Math.floor(hoursDifference / 24)

      if (daysDifference < 7) return `há ${daysDifference} dias`

      return `${updated.getDate().toString().padStart(2, '0')}/${(
        updated.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}/${updated.getFullYear()}`
    },
    status_icon () {
      return 'fal fa-bullhorn'
    },
    priority () {
      const priorityes = {
        4: 'danger',
        3: 'warning',
        2: 'normal',
        1: 'success'
      }
      return priorityes[this.item.internalFupPriorityId] || 'disabled'
    },
    daysAgo () {
      moment.locale('pt-br')
      return moment.utc(this.item.registered).fromNow()
    },
    avatar_color () {
      return '#' + Math.random().toString(16).substr(2, 6)
    },
    favoriteButtonData () {
      return {
        class: !this.item.favorite
          ? 'fa-heart-broken text-color-coral'
          : 'fa-heart',
        text: this.item.favorite
          ? 'Remover dos favoritos'
          : 'Adicionar aos favoritos'
      }
    }
  },
  methods: {
    ...mapActions({
      addFavorites: 'home/addFavorites',
      removeFavorite: 'home/removeFavorite',
      savePosition: 'home/savePosition',
      changeActivePhase: 'home/changeActivePhase',
      //getOnlySteps: 'home/getOnlySteps',
      getOnlyBusiness: 'home/getOnlyBusiness',
      getOnlyLeads: 'home/getOnlyLeads',
      getOnlyProspects: 'home/getOnlyProspects',
      getOnlyProjects: 'home/getOnlyProjects',
      getOnlyProspectsCount: 'home/getOnlyProspectsCount',
      getOnlyLeadsCount: 'home/getOnlyLeadsCount',
      getOnlyBusinessCount: 'home/getOnlyBusinessCount',
      getOnlyProjectsCount: 'home/getOnlyProjectsCount',
      getOnlyPlaceds: 'home/getOnlyPlaceds',
      setFiled: 'home/setFiled'
    }),
    async changePhase () {
      const position = Object.assign({}, this.item)
      const buttonOptions = {
        Lead: 2,
        Negócio: 3,
        Projeto: 4
      }
      const steps = {
        Lead: 2,
        Negócio: 3,
        Projeto: 4
      }
      const drawers = {
        1: 'openDrawerToLead',
        2: 'openDrawerToBusiness',
        3: 'openDrawerToProjects'
      }
      const functions = {
        2: (position) => {
          // TODO precisa validar os status e mostrar mensagens de erro para Leads
          position.prospectStatusId = 1
          position.leadStatusId = 2
          position.prospectStep = 2
          position.prospectStepDateCreated =
            moment().format('YYYY-MM-DD h:mm:ss')
          this.savePosition(position)
        },
        3: (position) => {
          // TODO precisa validar os status e mostrar mensagens de erro para negocios
          position.prospectStatusId = 1
          position.businessStatusId = 3
          position.chanceToCloseDeal = position.chanceToBecomeDeal
          position.businessStep = 3
          position.businessStepDateCreated =
            moment().format('YYYY-MM-DD h:mm:ss')
          this.savePosition(position)
        },
        4: (position) => {
          position.prospectStatusId = 1
          position.projectStatusId = 4
          position.projectStep = 4
          position.projectStepDateCreated =
            moment().format('YYYY-MM-DD h:mm:ss')
          this.savePosition(position)
        }
      }

      let nextStepName = ''
      if (this.type === 'prospect') {
        nextStepName = 'Lead'
      }

      if (this.type === 'leads') {
        nextStepName = 'Negócio'
      }

      if (this.type === 'negócios') {
        nextStepName = 'Projeto'
      }

      const step = (position.stepId = steps[nextStepName])

      await functions[step](position)
      await this.getOnlyProspects(),
        await this.getOnlyLeads(),
        await this.getOnlyBusiness(),
        await this.getOnlyProjects(),
        await this.getOnlyProspectsCount(),
        await this.getOnlyLeadsCount(),
        await this.getOnlyBusinessCount(),
        await this.getOnlyProjectsCount()

      this.changeActivePhase(buttonOptions[nextStepName])
      this.$emit('close')
      setTimeout(() => {
        const callback = drawers[step]
        if (callback) this.$emit(callback)
      }, 500)
    },
    getStatus () {
      const steps = {
        1: this.item.prospectStatus,
        2: this.item.leadStatus,
        3: this.item.businessStatus,
        4: this.item.projectsStatus
      }
      return steps[this.item.stepId]
    },
    avoidDetailsOpening (event, actionToRun) {
      event.stopPropagation()
      if (actionToRun instanceof Function) actionToRun()
    },
    openUserModal (data) {
      this.$emit('openUser', {
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        photo: data.photo,
        type: this.type,
        profile: 'Seleção'
      })
    },
    /*reloadCards () {
      switch (this.currentStep) {
        case 1: this.getOnlyProspects(); break;
        case 2: this.getOnlyLeads(); break;
        case 3: this.getOnlyBusiness(); break;
        case 4: this.getOnlyProjects(); break;
        case 5: this.getOnlyPlaceds(); break;
        default: break;
      }
    },*/
    favoriteItem () {
      if (!this.item.favorite) {
        this.addFavorites(this.item.id)
        //this.reloadCards();
        this.item.favorite = this.item.id
      } else {
        this.removeFavorite(this.item.id)
        this.item.favorite = false
      }
    },
    openKickoff (status) {
      if (status !== 12) return false
      this.$emit('openKickoff')
    },
    callDrawer (tab) {
      this.$emit('openDrawer', this.item.id, tab, this.item.stepId)
    },
    async changeFiled (pipelineId, filed) {
      await this.setFiled({ pipelineId: pipelineId, filed: filed })

      if (this.currentStep === 1) {
        await this.getOnlyProspects()
      }

      if (this.currentStep === 2) {
        await this.getOnlyLeads()
      }

      if (this.currentStep === 3) {
        await this.getOnlyBusiness()
      }

      if (this.currentStep === 4) {
        await this.getOnlyProjects()
      }

      if (this.currentStep === 5) {
        await this.getOnlyPlaceds()
      }
    }
  }
}
</script>
