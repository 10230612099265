<template>
  <div>
    <ul v-if="candidate && candidate.id">
      <li>
        <em class="fa-brands fa-linkedin-in"></em>
        <span v-if="candidate?.linkedin">
          <a target="_blank" :href="`${candidate?.linkedin}`">{{
            candidate?.linkedin
          }}</a>
        </span>
        <span v-else>...</span>
      </li>
      <li>
        <em class="fa-brands fa-envelope"></em>
        <span v-if="candidate.emails.length > 0">
          <a target="_blank" :href="`mailto:${candidate?.emails[0].email || candidate.email}`">{{
            candidate.emails[0].email
          }}</a>
        </span>
        <span v-else>...</span>
      </li>
      <li>
        <em class="fa-brands fa-whatsapp"></em>
        <span v-if="candidate.phones[0]?.phone">
          <a target="_blank"
            :href="`https://api.whatsapp.com/send?phone=55${candidate.phones[0].phone.replace(/\D/g, '').replace('+55', '')}`">{{
              candidate.phones[0].phone }}</a>
        </span>
        <span v-else>...</span>
      </li>
    </ul>
    <div class="candidate-form d-flex flex-wrap pt-2 pb-2">
      <div class="candidate-form d-flex flex-wrap pt-2 pb-2">
        <div class="w-50">
          <b-autocomplete v-model="status" label="Status" :options="statusData" option-text="status_description"
            option-value="id" @change="selectStatus" />
        </div>
        <div class="w-50">
          <b-autocomplete v-model="motive" label="Motivo" :options="motives" option-text="motive_description"
            option-value="id" @change="selectMotive" />
        </div>
        <div class="w-100">
          <b-textfield v-model="observation" :placeholder="observation" label="Observações" :multiline="true"
            @change="updateObservation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Dados',
  props: {
    candidate: {
      type: Object
    }
  },
  data () {
    return {
      status: '',
      motive: '',
      observation: '',
      statusData: [
        {
          id: 1,
          status_description: 'Entrevistado Plongê',
          label: 'normal'
        },
        {
          id: 2,
          status_description: 'Indicação cliente',
          label: 'referral'
        },
        {
          id: 3,
          status_description: 'Tentando contato',
          label: 'warning'
        },
        {
          id: 4,
          status_description: 'Sem interesse',
          label: 'warning'
        },
        {
          id: 5,
          status_description: 'Não aprovado',
          label: 'rejected'
        },
        {
          id: 6,
          status_description: 'Em contato',
          label: 'normal'
        },
        {
          id: 7,
          status_description: 'Abordado',
          label: 'normal'
        },
        {
          id: 8,
          status_description: 'Apresentado',
          label: 'normal'
        },
        {
          id: 9,
          status_description: 'Entrevistado cliente',
          label: 'normal'
        },
        {
          id: 10,
          status_description: 'Off Limits cliente',
          label: 'normal'
        },
        {
          id: 11,
          status_description: 'Proposta enviada',
          label: 'normal'
        },
        {
          id: 12,
          status_description: 'Proposta rejeitada',
          label: 'normal'
        },
        {
          id: 13,
          status_description: 'Placed',
          label: 'normal'
        },
        {
          id: 14,
          status_description: 'Não abordado',
          label: 'normal'
        },
        {
          id: 15,
          status_description: 'Aprovado',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.status_description.toLowerCase()
        const nameB = b.status_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motivesReproved: [
        {
          id: 1,
          motive_description: 'Remuneração',
          label: 'normal'
        },
        {
          id: 2,
          motive_description: 'Idioma',
          label: 'normal'
        },
        {
          id: 3,
          motive_description: 'Júnior',
          label: 'normal'
        },
        {
          id: 4,
          motive_description: 'Sênior',
          label: 'normal'
        },
        {
          id: 5,
          motive_description: 'Técnico',
          label: 'normal'
        },
        {
          id: 6,
          motive_description: 'Perfil pessoal',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveInterest: [
        {
          id: 8,
          motive_description: 'Localização',
          label: 'normal'
        },
        {
          id: 9,
          motive_description: 'Momento da carreira',
          label: 'normal'
        },
        {
          id: 10,
          motive_description: 'Momento pessoal',
          label: 'normal'
        },
        {
          id: 11,
          motive_description: 'Pacote de remuneração',
          label: 'normal'
        },
        {
          id: 12,
          motive_description: 'Perfil de posição',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveNotAddressed: [
        {
          id: 13,
          motive_description: 'Stand by',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveAddressed: [
        {
          id: 14,
          motive_description: 'Stand by',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveInterview: [
        {
          id: 15,
          motive_description: 'Desistente',
          label: 'normal'
        },
        {
          id: 16,
          motive_description: 'Não aprovado',
          label: 'reject'
        },
        {
          id: 17,
          motive_description: 'Stand by',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveIntroduced: [
        {
          id: 18,
          motive_description: 'Não aprovado',
          label: 'reject'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motiveInterviewClient: [
        {
          id: 19,
          motive_description: 'Não aprovado',
          label: 'reject'
        },
        {
          id: 20,
          motive_description: 'Stand by',
          label: 'normal'
        },
        {
          id: 21,
          motive_description: 'Desistente',
          label: 'normal'
        }
      ].sort((a, b) => {
        const nameA = a.motive_description.toLowerCase()
        const nameB = b.motive_description.toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      }),
      motives: [],
      disabledMotive: false
    }
  },
  computed: {
    sortStatus () {
      return this.statusData.toSorted((a, b) => {
        const nameA = a.status_description.toLowerCase()
        const nameB = b.status_description.toLowerCase()

        return nameA - nameB
      })
    }
  },
  watch: {
    candidate (value) {
      if (!value) {
        this.status = ''
        this.motive = ''
        this.observation = ''
        return
      }
      this.status = this.filteredStatus(value.status)?.id
      this.motive = this.filteredMotives(value.motive)?.id
      this.observation = this.candidate.observation
    },
    status: function () {
      if (this.status === 1) {
        this.disabledMotive = false
        this.motives = this.motiveInterview
      } else if (this.status === 5) {
        this.disabledMotive = false
        this.motives = this.motivesReproved
      } else if (this.status === 4) {
        this.disabledMotive = false
        this.motives = this.motiveInterest
      } else if (this.status === 12) {
        this.disabledMotive = false
        this.motives = this.motiveInterest
      } else if (this.status === 14) {
        this.disabledMotive = false
        this.motives = this.motiveNotAddressed
      } else if (this.status === 7) {
        this.disabledMotive = false
        this.motives = this.motiveAddressed
      } else if (this.status === 8) {
        this.disabledMotive = false
        this.motives = this.motiveIntroduced
      } else if (this.status === 9) {
        this.disabledMotive = false
        this.motives = this.motiveInterviewClient
      } else {
        this.motives = []
        this.motive = ''
        this.disabledMotive = true
      }
    }
  },
  methods: {
    ...mapActions({
      updateCandidancy: 'jobs/updateCandidancy',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    filteredStatus (status) {
      switch (status) {
        case 1:
          return {
            id: 1,
            status_description: 'Entrevistado Plongê',
            label: 'normal'
          }
        case 2:
          return {
            id: 2,
            status_description: 'Indicação cliente',
            label: 'referral'
          }
        case 3:
          return {
            id: 3,
            status_description: 'Tentando contato',
            label: 'warning'
          }
        case 4:
          return {
            id: 4,
            status_description: 'Sem interesse',
            label: 'warning'
          }
        case 5:
          return {
            id: 5,
            status_description: 'Reprovado',
            label: 'rejected'
          }
        case 6:
          return {
            id: 6,
            status_description: 'Em contato',
            label: 'normal'
          }
        case 7:
          return {
            id: 7,
            status_description: 'Abordado',
            label: 'normal'
          }
        case 8:
          return {
            id: 8,
            status_description: 'Apresentado',
            label: 'normal'
          }
        case 9:
          return {
            id: 9,
            status_description: 'Entrevistado Cliente',
            label: 'normal'
          }
        case 10:
          return {
            id: 10,
            status_description: 'Off Limits Cliente',
            label: 'normal'
          }
        case 11:
          return {
            id: 11,
            status_description: 'Proposta enviada',
            label: 'normal'
          }
        case 12:
          return {
            id: 12,
            status_description: 'Proposta rejeitada',
            label: 'normal'
          }
        case 13:
          return {
            id: 13,
            status_description: 'Placed',
            label: 'normal'
          }
        case 14:
          return {
            id: 14,
            status_description: 'Não abordado',
            label: 'normal'
          }
        case 15:
          return {
            id: 15,
            status_description: 'Aprovado',
            label: 'success'
          }
      }
    },
    filteredMotives (motive) {
      switch (motive) {
        case 1:
          return {
            id: 1,
            motive_description: 'Remuneração',
            label: 'normal'
          }
        case 2:
          return {
            id: 2,
            motive_description: 'Idioma',
            label: 'normal'
          }
        case 3:
          return {
            id: 3,
            motive_description: 'Júnior',
            label: 'normal'
          }
        case 4:
          return {
            id: 4,
            motive_description: 'Sênior',
            label: 'normal'
          }
        case 5:
          return {
            id: 5,
            motive_description: 'Técnico',
            label: 'normal'
          }
        case 6:
          return {
            id: 6,
            motive_description: 'Perfil pessoal',
            label: 'normal'
          }
        case 7:
          return {
            id: 7,
            motive_description: 'Desistente',
            label: 'normal'
          }
        case 8:
          return {
            id: 8,
            motive_description: 'Localização',
            label: 'normal'
          }
        case 9:
          return {
            id: 9,
            motive_description: 'Momento de Carreira',
            label: 'normal'
          }
        case 10:
          return {
            id: 10,
            motive_description: 'Momento pessoal',
            label: 'normal'
          }
        case 11:
          return {
            id: 11,
            motive_description: 'Pacote de Remuneração',
            label: 'normal'
          }
        case 12:
          return {
            id: 12,
            motive_description: 'Perfil de posição',
            label: 'normal'
          }
        case 13:
          return {
            id: 13,
            motive_description: 'Stand by',
            label: 'normal'
          }
        case 14:
          return {
            id: 14,
            motive_description: 'Stand by',
            label: 'normal'
          }
        case 15:
          return {
            id: 15,
            motive_description: 'Desistente',
            label: 'normal'
          }
        case 16:
          return {
            id: 16,
            motive_description: 'Não aprovado',
            label: 'reject'
          }
        case 17:
          return {
            id: 17,
            motive_description: 'Stand by',
            label: 'normal'
          }
        case 18:
          return {
            id: 18,
            motive_description: 'Não aprovado',
            label: 'reject'
          }
        case 19:
          return {
            id: 19,
            motive_description: 'Não aprovado',
            label: 'reject'
          }
        case 20:
          return {
            id: 20,
            motive_description: 'Stand by',
            label: 'normal'
          }
        case 21:
          return {
            id: 21,
            motive_description: 'Desistente',
            label: 'normal'
          }
      }
    },
    selectStatus () {
      setTimeout(() => {
        this.updateCandidancy({
          id: this.candidate.id,
          status: this.status,
          candidate: this.candidate
        })
      }, 2000)
    },
    selectMotive () {
      setTimeout(() => {
        this.updateCandidancy({
          id: this.candidate.id,
          motive: this.motive,
          candidate: this.candidate
        })
      }, 2000)
    },
    updateObservation () {
      if (this.observation.length > 1000) {
        this.showSnackbar({
          message: 'Quantidade de carácteres precisa ser menor que 1000',
          secondsToClose: 10,
          type: 'error'
        })
        return
      }

      this.updateCandidancy({
        observation: this.observation,
        id: this.candidate.id,
        candidate: this.candidate
      })
    }
  },
  mounted () {
    if (!this.candidate) {
      this.status = ''
      this.motive = ''
      this.observation = ''
      return
    }

    this.status = this.filteredStatus(this.candidate.status)?.id
    this.motive = this.filteredMotives(this.candidate.motive)?.id
    this.observation = this.candidate.observation
  }
}
</script>

<style scoped lang="scss">
.candidate-form {
  gap: 4px;
}

.w-50 {
  width: calc(50% - 2px);
}

.btn {
  margin-right: 24px;
}

ul {
  width: 100%;

  &>li {
    height: 42px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

a {
  color: var(--text-default);
}
</style>
